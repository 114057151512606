import React from "react";
import styled from "styled-components";
import { FormsGrid } from "../../components/psychosocialAssessment/PsychosocialFormsGrid";

const PsychosocialAssessmentPageContainer = styled.div`
  display: flex;
  padding: 27px 20px;
  max-width: 1040px;
  margin: 0 auto;
`;

export const FormsPage = () => {
  return (
    <PsychosocialAssessmentPageContainer>
      <FormsGrid />
    </PsychosocialAssessmentPageContainer>
  );
};
