import { useState, useEffect } from "react";
import { Box, Button, MenuItem, Select, styled, Typography } from "@mui/material";
import { useGetCanceledBillingStatusQuery, usePutBillingStatusMutation } from "../../services/ClientService";
import useFetchWithMsal from "../../utils/useFetchWithMsal";
import ErrorAlert from "../../shared/components/ErrorAlert";
import { setError } from "../../store/slices/ErrorSlice";
import { useDispatch } from "react-redux";
import { FaHourglassHalf, FaCheckCircle, FaTimesCircle, FaBan, FaTrashAlt, FaRedoAlt, FaDollarSign, FaFileAlt, FaSyncAlt, FaEraser } from 'react-icons/fa';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import DeleteIcon from '@mui/icons-material/Delete';
import BlockIcon from '@mui/icons-material/Block';
import RedoIcon from '@mui/icons-material/Redo';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DescriptionIcon from '@mui/icons-material/Description';
import SyncIcon from '@mui/icons-material/Sync';
import ErrorIcon from '@mui/icons-material/Error';
import HistoryIcon from '@mui/icons-material/History';
import BackupIcon from "@mui/icons-material/Backup";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import SendIcon from "@mui/icons-material/Send";
import PaidIcon from "@mui/icons-material/Paid";

const StyledBillingStatusSelect = styled(Select)(() => ({
  //background: '#fff',
}));

const StyledButton = styled(Button)(() => ({
  color: 'rgb(189, 152, 106)',
  fontSize: '10px',
  marginLeft: '10px'
}));

const billingStatusIcons = {
  'Submitted - Pending': <HourglassEmptyIcon fontSize="small" style={{ marginRight: '8px', marginTop: '4px', color: 'gray' }} />,
  'Write-Off': <DeleteIcon fontSize="small" style={{ marginRight: '8px', marginTop: '4px', color: 'gray' }} />,
  'Void': <BlockIcon fontSize="small" style={{ marginRight: '8px', marginTop: '4px', color: 'gray' }} />,
  'Denied': <CancelIcon fontSize="small" style={{ marginRight: '8px', marginTop: '4px', color: 'gray' }} />,
  'Adjusted': <RedoIcon fontSize="small" style={{ marginRight: '8px', marginTop: '4px', color: 'gray' }} />,
  'Partial payment': <AttachMoneyIcon fontSize="small" style={{ marginRight: '8px', marginTop: '4px', color: 'gray' }} />,
  'Paid in full': <CheckCircleIcon fontSize="small" style={{ marginRight: '8px', marginTop: '4px', color: 'gray' }} />,
  'Rejected': <CancelIcon fontSize="small" style={{ marginRight: '8px', marginTop: '4px', color: 'gray' }} />,
  'Un-Billed': <DescriptionIcon fontSize="small" style={{ marginRight: '8px', marginTop: '4px', color: 'gray' }} />,
  'Re-Submit': <SyncIcon fontSize="small" style={{ marginRight: '8px', marginTop: '4px', color: 'gray' }} />,
  'Error': <ErrorIcon fontSize="small" style={{ marginRight: '8px', marginTop: '4px', color: 'gray' }} />,
};

export const BillingStatusSelect = ({ name, id, onStatusChangeSuccess }) => {
  const [billingStatus, setBillingStatus] = useState();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const { skip } = useFetchWithMsal();
  const dispatch = useDispatch();

  const { data: canceledBillingStatuses } = useGetCanceledBillingStatusQuery({ skip });
  const [putBillingStatus] = usePutBillingStatusMutation();

  // Sync the state with the prop when it changes
  useEffect(() => {
    if (canceledBillingStatuses && name) {
      const currentStatus = canceledBillingStatuses.find((status) => status.name === name);
      if (currentStatus) {
        setBillingStatus(currentStatus.name); // Set the name when data is available
      } else {
        setBillingStatus(''); // Fallback to empty string if no match is found
      }
    }
  }, [name, canceledBillingStatuses]);

  const handleBillingStatusChange = (event) => {
    const newStatus = event.target.value;
    console.log("new status", newStatus);

    const selectedStatus = canceledBillingStatuses?.find((status) => status.id === newStatus);
    console.log("selected status", selectedStatus);
    if (selectedStatus) {
      console.log("selected status", selectedStatus.name);
      setBillingStatus(selectedStatus.name); // Update the dropdown display with the name

      const newRecord = {
        id: 0,
        serviceId: id,
        statusId: selectedStatus.id, // Use the ID for the API call
        userId: "",
        note: "",
      };

      putBillingStatus({ newRecord })
        .then((response) => {
          if (response?.error?.message) {
            //setOpen(true);
            setMessage(response?.error?.message);
          } else {
            //setOpen(true);
            setMessage('Status changed successfully');
            onStatusChangeSuccess();
          }
        })
        .catch((error) => {
          console.error("Error updating billing status:", error);
        });
    } else {
      console.error("Selected status not found");
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    dispatch(setError(""));
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" mb={1}>
        <Typography variant="body1" component="label" htmlFor="billing-status-select" sx={{ marginRight: 2 }}>
          Change Billing Status
        </Typography>
        <StyledBillingStatusSelect
          id="billing-status-select"
          value={billingStatus}
          onChange={handleBillingStatusChange}
          sx={{ marginRight: 2 }}
        >
          <MenuItem value={name}>
            {name}
          </MenuItem>
          {canceledBillingStatuses &&
            canceledBillingStatuses.map(
              (status) =>
                name !== status.name && (
                  <MenuItem key={status.id} value={status.id}>
                    {billingStatusIcons[status.name]} {status.name}
                  </MenuItem>
                )
            )}
        </StyledBillingStatusSelect>
      </Box>
      {/* <StyledButton onClick={changeBillingStatus} disabled={billingStatus === name} variant="text">Change status</StyledButton>
      <ErrorAlert severity={message === 'Status changed successfully' ? 'success' : 'error'} handleClose={handleClose} open={open} errorMessage={message} /> */}
    </Box>
  );
}