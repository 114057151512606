import React, { useCallback, useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import InputField from "../../common/InputField";
import DatePickerField from "../../common/DatePickerField";
import SelectField from "../../common/SelectFieldForForm";
import RadioGroup from "../../common/RadioGroup";
import CheckboxGroup from "../../common/CheckboxGroup";
import SearchableSelect from "../../common/SearchableSelect";
import { Field } from "formik";
import DynamicElementsComponent from "../../table/dynamicElementsComponent/DynamicElementsComponent";
import TextAreaField from "../../common/TextAreaField";
import HelpTextField from "../HelpTextField";
import { FileUpload } from "../../common/FileUpload";
import AutocompleteWithCheckboxes from "../../../shared/components/AutocompleteWithCheckboxes";
import SelectWithCheckbox from "../../common/SelectWithCheckbox";
import { GridTypeElementComponent } from "../../table/gridTypeElementComponent/GridTypeElementComponent";
import { AllergyList } from "../../clientInfo/accordionItem/allergyList/AllergyList";
import { MedicationDetailsFormGrid } from "../../clientInfo/accordionItem/medicationDetails/MedicationDetailsFormGrid";

const AssessmentItem = React.memo(({ formFields }) => {
  const [sortedFields, setSortedFields] = useState([]);
  const [isReasonFieldShow, setIsReasonFieldShow] = useState(false);

  useEffect(() => {
    if (formFields) {
      const sortedArray = formFields
        ?.slice()
        .sort((a, b) => a.priority - b.priority);
      setSortedFields(sortedArray);
    }
  }, [formFields]);

  const getAttendanceStatusValue = (fieldId, formValues) => {
    if (formValues.hasOwnProperty(fieldId)) {
        return formValues[fieldId];
    } else {
        return null;
    }
  }

  const getAttendanceStatusReasonValues = statuses => {
    const allowedNames = ["Client cancelled", "Office cancelled", "Other cancelled", "Client Refused"];
    return statuses.filter(item => allowedNames.includes(item.name));
  }

  const getInput = useCallback((field, setValue, value, error, form) => {
    if(field.name === 'Attendance Status') {
      const attendanceStatusReasonsValues = getAttendanceStatusReasonValues(field.possibleValues);
      const attendanceStatusValue = getAttendanceStatusValue(field.id, form.values);
      if (attendanceStatusReasonsValues.some(item => item.id === attendanceStatusValue)){
        setIsReasonFieldShow(true)
      } else {
        setIsReasonFieldShow(false)
      }
    }
    switch (field.type) {
      case 1:
        return (
          <InputField
            name={String(field.id)}
            value={value}
            setValue={setValue}
            error={error}
            fullWidth
            required={field.required}
          />
        );
      case 2:
        return (
          <InputField
            name={String(field.id)}
            value={value}
            setValue={setValue}
            error={error}
            type="number"
            fullWidth
            required={field.required}
          />
        );
      case 3:
        return (
          <RadioGroup
            name={String(field.id)}
            items={field.possibleValues}
            value={value}
            setValue={setValue}
            error={error}
            id={field.id}
            required={field.required}
          />
        );
      case 4:
        return (
          <SelectField
            name={String(field.id)}
            data={field.possibleValues}
            value={value}
            error={error}
            setValue={setValue}
            fullWidth
            required={field.required}
          />
        );
      case 5:
        return (
          <SearchableSelect
            name={String(field.id)}
            value={value}
            setValue={setValue}
            error={error}
            fullWidth
            required={field.required}
          />
        );
      case 6:
        return (
          <CheckboxGroup
            name={String(field.id)}
            id={field.id}
            setValue={setValue}
            value={value}
            error={error}
            fields={field.possibleValues}
            required={field.required}
          />
        );
      case 7:
        const isNextLabel = field.label?.toLowerCase().includes("next") || false;

        return (
          <DatePickerField
            name={String(field.id)}
            label={field.label}
            setValue={setValue}
            value={value}
            error={error}
            //minDate={isNextLabel ? new Date() : null} // Min date is today if "next" is in the label
            //maxDate={isNextLabel ? null : new Date()} // Max date is today if "next" is NOT in the label
            required={field.required}
          />
        );
      case 8:
        return (
          <TextAreaField
            name={String(field.id)}
            value={value}
            setValue={setValue}
            error={error}
            fullWidth
            required={field.required}
          />
        );
      case 9:
        return (
          <DynamicElementsComponent name={String(field.id)} value={value} setValue={setValue} type={9}/>
        );
      case 10:
        return (
          <HelpTextField/>
        );
      case 11:
        return (
          <DynamicElementsComponent name={String(field.id)} value={value} setValue={setValue} type={11}/>
        );
      case 12:
        return (
          <DynamicElementsComponent name={String(field.id)} value={value} setValue={setValue} type={12}/>
        );
      case 13:
        return (
          <FileUpload name={String(field.id)} value={value} setValue={setValue}/>
        );
      case 14:
        return (
          <SelectWithCheckbox
            name={String(field.id)}
            value={value}
            data={field.possibleValues}
            setValue={setValue}
            error={error}
            width={'280px'}
            multiple
          />
        );
      case 15:
        return (
          <GridTypeElementComponent name={String(field.id)} value={value} setValue={setValue}/>
        );
      case 16:
        return (
          <AllergyList name={String(field.id)} values={value} setValue={setValue} />
        );
      case 17:
        return (
          <MedicationDetailsFormGrid name={String(field.id)} values={value} setValue={setValue} />
        );
      default:
        return (
          <InputField
            name={String(field.id)}
            value={value}
            setValue={setValue}
            error={error}
            fullWidth
            required={field.required}
          />
        );
    }
  }, []);
  return (
    <>
     
      {sortedFields?.map((item) => {
        return (
          (item.name !== 'Reason' || isReasonFieldShow) && <Grid container mb={"30px"} columnSpacing={"66px"} key={item.id}>
            {(item.type !== 9 && item.type !== 11 && item.type !== 12 && item.type !== 15 && item.type !== 16  && item.type !== 17) &&
              <Grid item xs={4}>
                <Typography>{item.name}{item.required && '*'}</Typography>
              </Grid>
            }
            <Grid item xs={`${(item.type !== 9 && item.type !== 11 && item.type !== 12 && item.type !== 15 && item.type !== 16 && item.type !== 17) ? 8 : 12}`}>
              <Field name={item.id}>
                {({ field, form }) =>
                  getInput(
                    item,
                    form.setFieldValue,
                    field.value,
                    form.errors[item.id],
                    form
                  )
                }
              </Field>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
});
export default AssessmentItem;
