import * as React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';

const rows = [
  {
    label: 'Head of Human Resources',
    recruitmentDate: new Date(2020, 8, 12),
    id: 0,
  },
  {
    label: 'Head of Sales',
    recruitmentDate: new Date(2017, 3, 4),
    id: 1,
  },
];

const columns = [
  { field: 'jobTitle', headerName: 'Job Title', width: 200 },
  {
    field: 'recruitmentDate',
    headerName: 'Recruitment Date',
    type: 'date',
    width: 150,
  },
];

const getTreeDataPath = (row) => row.hierarchy;

export const TestPage = () => {
  return (
    <div style={{ height: 400, width: '100%' }}>
        <RichTreeView
          items={rows}
          isItemEditable
          experimentalFeatures={{ labelEditing: true }}
          defaultExpandedItems={['grid', 'pickers']}
        />
    </div>
  );
}