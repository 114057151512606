import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { Box, Button, Typography, Snackbar, Alert, IconButton, Divider, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { RichTreeViewPro } from "@mui/x-tree-view-pro/RichTreeViewPro";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import AddIcon from "@mui/icons-material/Add";

import { useTreeItem2 } from "@mui/x-tree-view/useTreeItem2";
import {
  TreeItem2Content,
  TreeItem2IconContainer,
  TreeItem2GroupTransition,
  TreeItem2Label,
  TreeItem2Root,
  TreeItem2Checkbox,
} from "@mui/x-tree-view/TreeItem2";
import { TreeItem2Icon } from "@mui/x-tree-view/TreeItem2Icon";
import { TreeItem2Provider } from "@mui/x-tree-view/TreeItem2Provider";
import { TreeItem2DragAndDropOverlay } from "@mui/x-tree-view/TreeItem2DragAndDropOverlay";

import {
  useGetAssessmentFormQuery,
  usePostAssessmentValuesMutation,
} from "../../services/ClientService";
import { useDispatch } from "react-redux";
import { setError } from "../../store/slices/ErrorSlice";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import NumbersIcon from "@mui/icons-material/Numbers";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DateRangeIcon from "@mui/icons-material/DateRange";
import TextareaIcon from "@mui/icons-material/TextSnippet";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import GridOnIcon from "@mui/icons-material/GridOn";
import MedicationIcon from "@mui/icons-material/Medication";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import LayersIcon from '@mui/icons-material/Layers';

const fieldTypeIcons = {
  1: <TextFieldsIcon />, // Text field
  2: <NumbersIcon />, // Number field
  3: <RadioButtonCheckedIcon />, // Radio group
  4: <RadioButtonCheckedIcon />, // Select field
  5: <RadioButtonCheckedIcon />, // Searchable select
  6: <CheckBoxIcon />, // Checkbox group
  7: <DateRangeIcon />, // Date picker
  8: <TextareaIcon />, // Text area
  9: <GridOnIcon />, // Dynamic elements (type 9)
  10: <WarningAmberIcon />, // Help text
  13: <UploadFileIcon />, // File upload
  16: <WarningAmberIcon />, // Allergy list
  17: <MedicationIcon />, // Medication details
};


const EditableTreeItem = React.forwardRef(function EditableTreeItem(props, ref) {
  const { id, itemId, label, type, children, disabled, ...other } = props;

  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getCheckboxProps,
    getLabelProps,
    getGroupTransitionProps,
    getDragAndDropOverlayProps,
    status,
    publicAPI,
  } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

  const item = publicAPI.getItem(itemId);
  const icon = getIconFromType(item.type);
  const expandable = Array.isArray(children) && children.length > 0;

  return (
    <TreeItem2Provider itemId={itemId}>
      <TreeItem2Root {...getRootProps(other)}>
        <TreeItem2Content
          {...getContentProps()}
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '0.5rem',
          }}
        >
          <TreeItem2IconContainer {...getIconContainerProps()}>
            <TreeItem2Icon status={status} />
          </TreeItem2IconContainer>
          <TreeItem2Checkbox {...getCheckboxProps()} />
          <CustomLabel
            {...getLabelProps()}
            icon={icon}
            expandable={expandable && status.expanded}
          >
            {label}
          </CustomLabel>
          <TreeItem2DragAndDropOverlay {...getDragAndDropOverlayProps()} />
        </TreeItem2Content>
        {children && <TreeItem2GroupTransition {...getGroupTransitionProps()} />}
      </TreeItem2Root>
    </TreeItem2Provider>
  );
});

const getIconFromType = (type) => {
  switch (type) {
    case 1: return TextFieldsIcon; // Text field
    case 2: return NumbersIcon; // Number field
    case 3: return RadioButtonCheckedIcon; // Radio group
    case 4: return RadioButtonCheckedIcon; // Select field
    case 5: return RadioButtonCheckedIcon; // Searchable select
    case 6: return CheckBoxIcon; // Checkbox group
    case 7: return DateRangeIcon; // Date picker
    case 8: return TextareaIcon; // Text area
    case 9: return GridOnIcon; // Dynamic elements
    case 10: return WarningAmberIcon; // Help text
    case 13: return UploadFileIcon; // File upload
    case 16: return WarningAmberIcon; // Allergy list
    case 17: return MedicationIcon; // Medication details
    default: return LayersIcon; // Default icon
  }
};

const CustomLabel = ({ icon: Icon, expandable, children, ...other }) => {
  return (
    <TreeItem2Label
      {...other}
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {Icon && (
        <Box
          component={Icon}
          sx={{ mr: 1, fontSize: '1.2rem' }}
          color="inherit"
        />
      )}
      <Typography variant="body2" sx={{ fontWeight: 500, color: 'inherit' }}>
        {children}
      </Typography>
    </TreeItem2Label>
  );
};

export const ManagePsychosocialAssessment = (props) => {
  const { propFormId } = props;

  const { data, isLoading, refetch: refetchAssessmentForm } = useGetAssessmentFormQuery(propFormId);
  const [postAssessmentValues, { error }] = usePostAssessmentValuesMutation();
  const dispatch = useDispatch();
  const [treeItems, setTreeItems] = useState([]);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [mode, setMode] = useState("Edit"); // Toggle mode

  useEffect(() => {
    dispatch(setError(error?.message));
  }, [error]);

  useEffect(() => {
    if (data) {
      const items = [...(data.sections || [])]
        .sort((a, b) => (a.priority || 0) - (b.priority || 0))
        .map((section) => ({
          id: section.id.toString(),
          label: section.name,
          children: section.formFields
            ? [...section.formFields]
              .sort((a, b) => (a.priority || 0) - (b.priority || 0))
              .map((field) => ({
                id: `field-${field.id}`,
                label: field.label || field.name,
                type: field.type,
              }))
            : [],
        }));
      console.log("items", items);
      setTreeItems(items);
    }
  }, [data]);

  const handleModeChange = (event, newMode) => {
    if (newMode) {
      setMode(newMode);
    }
  };

  const handleRename = (itemId, newName) => {
    setTreeItems((prev) =>
      prev.map((item) =>
        item.id === itemId
          ? { ...item, label: newName }
          : {
            ...item,
            children: item.children.map((child) =>
              child.id === itemId ? { ...child, label: newName } : child
            ),
          }
      )
    );
  };

  const handleAddItem = (sectionId) => {
    const newField = {
      id: `field-${Date.now()}`,
      label: "New Field",
    };
    setTreeItems((prev) =>
      prev.map((section) =>
        section.id === sectionId
          ? { ...section, children: [...section.children, newField] }
          : section
      )
    );
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  const renderTreeItemsWithAdd = (items) =>
    items.map((item) => ({
      ...item,
      showAddBetween: true, // Always enable hover functionality
      children: renderTreeItemsWithAdd(item.children || []),
    }));

  const onSubmit = async (values) => {
    try {
      await postAssessmentValues({ formId: propFormId, value: values }).unwrap();
      setSnackbarMessage("Form updated successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      refetchAssessmentForm();
    } catch (err) {
      setSnackbarMessage(err?.message || "Failed to update form. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  return (
    <Box sx={{ minWidth: "500px", position: "relative", width: "100%", maxHeight: '80%', }}>
      {isLoading && <p>Loading...</p>}
      {data && (
        <Formik initialValues={{}} onSubmit={onSubmit}>
          {() => (
            <Form>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "16px",
                }}
              >
                <Box>
                  <Typography variant="h6">{data.formTitle}</Typography>
                </Box>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Button variant="outlined" size="small" type="reset">
                    Discard
                  </Button>
                  <Button variant="contained" size="small" type="submit">
                    Save
                  </Button>
                </Box>
              </Box>
              <Box>
                <RichTreeViewPro
                  items={renderTreeItemsWithAdd(treeItems)}
                  defaultExpandedItems={treeItems.map((item) => item.id)}
                  experimentalFeatures={{
                    labelEditing: mode === "Edit",
                    indentationAtItemLevel: true,
                    itemsReordering: mode === "Order",
                  }}
                  slots={{
                    item: mode === "Edit" ? EditableTreeItem : undefined,
                  }}
                  itemsReordering={mode === "Order"}
                  sx={{
                    height: '600px', // Fixed height
                    overflowY: 'auto', // Enable vertical scrolling
                    overflowX: 'hidden', // Hide horizontal scrolling (if needed)
                    border: '1px solid', // Optional: add a border for visual distinction
                    borderColor: 'divider', // Optional: use theme's divider color
                  }}
                />
              </Box>
              <ToggleButtonGroup
                value={mode}
                exclusive
                onChange={handleModeChange}
                aria-label="Tree View Mode"
                sx={{ marginTop: "8px" }} // Adds spacing between the title and the buttons
              >
                <ToggleButton value="Edit" size="small" aria-label="Edit">
                  Edit
                </ToggleButton>
                <ToggleButton value="Order" size="small" aria-label="Order">
                  Order
                </ToggleButton>
              </ToggleButtonGroup>
            </Form>
          )}
        </Formik>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};
