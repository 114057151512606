import React, { useEffect, useMemo, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
    useMsal,
} from "@azure/msal-react";
import { Formik, Form, Field, FieldArray, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Button, Grid, Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import {
    useSaveClientAuthorizationMutation,
    useGetClientAuthorizationQuery,
    useGetAuthorizationStatusesQuery,
    useGetAuthorizationDenialReasonsQuery,
    useGetAssignmentClientsMutation,
} from '../../services/ClientService';
import ClientAuthorizedServiceForm from './ClientAuthorizedServiceForm';
import AutocompleteFieldForFilters from "../../shared/components/AutocompleteFieldForFilters";
import DatePickerField from '../common/DatePickerField';
import SelectField from '../common/SelectFieldForForm';
import CheckboxField from '../common/CheckboxField';
import InputField from '../common/InputField';
import list from "../../shared/icons/list.svg";
import history from "../../shared/icons/history.svg";
import bluePen from "../../shared/icons/bluePen.svg";
import blueTrash from "../../shared/icons/blueTrash.svg";
import addNote from "../../shared/icons/addNote.svg";
import employees from "../../shared/icons/sidebarMenu/employees.svg";
import roundedPlus from "../../shared/icons/roundedPlus.svg";
import SaveIcon from '@mui/icons-material/Save';
import {
    ButtonContainer,
    ButtonsContainer,
    ClientsTitleContainer,
    EmptyMessage,
    HeaderContainer,
    ResultData,
    TableControlsContainer,
    TableSearchContainer,
    ToolsImg,
    UploadFileContainer,
    StyledButton,
} from './ClientAuthorizationForm.styled';
import TextareaField from '../common/TextAreaField';
import InputFieldFormik from '../common/InputFieldFormik';
import DatePickerFieldFormik from '../common/DatePickerFieldFormik';
import TextAreaFieldFormik from '../common/TextAreaFieldFormik';
import SelectFieldFormik from '../common/SelectFieldFormik';
import useFetchWithMsal from '../../utils/useFetchWithMsal';
import SearchableSelect from '../common/SearchableSelect';


// Define validation schema
/* const ClientAuthorizationSchema = Yup.object().shape({
    ClientId: Yup.number().required('ClientId is required'),
    Code: Yup.string(),
    AuthorizationNumber: Yup.string(),
    AuthorizationDate: Yup.date().nullable(),
    ExpirationDate: Yup.date().nullable(),
    RequestDate: Yup.date().nullable(),
    AuthorizationStatus: Yup.number().nullable(),
    DenialId: Yup.number().nullable(),
    Notes: Yup.string(),
    CreatedBy: Yup.string(),
    CreateDate: Yup.date().nullable(),
    InsuranceName: Yup.string(),
    IsDeleted: Yup.boolean().nullable(),
    AuthorizedServices: Yup.array().of(
        Yup.object().shape({
            ProcedureCode: Yup.string(),
            Modifier: Yup.string().nullable(),
            Rate: Yup.number().nullable(),
            Units: Yup.number().nullable(),
            ServiceId: Yup.number().nullable(),
            IsDeleted: Yup.boolean().nullable(),
        })
    ),
}); */

const isEqual = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);

const ClientAuthorizationForm = ({ record, onSuccess, onClose }) => {

    const { skip, setSkip } = useFetchWithMsal();
    const theme = useTheme();

    useEffect(() => {
        console.log('ClientAuthorizationForm record:', record);
    }, [record]);

    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const userName = activeAccount?.idTokenClaims?.given_name || "Unknown User";
    const userGuid = activeAccount?.idTokenClaims?.oid || '';

    const { data: authStatuses } = useGetAuthorizationStatusesQuery({ skip });
    const { data: denialReasons } = useGetAuthorizationDenialReasonsQuery({ skip });
    const showAuthorizationNumberIds = [5, 6, 12, 13, 14];
    const approvedStatusId = 5;
    const deniedStatusId = 7;

    const [getClients, { data: clients }] = useGetAssignmentClientsMutation()

    useEffect(() => {
        getClients({
            startDate: null,
            endDate: null,
            isShowAll: true,
        });
    }, []);

    const { client } = useSelector(
        (state) => state.filter
    );

    useEffect(() => {
        if (clients) {
            // Transform the data to an array of { id, name } objects
            const transformedClients = clients.map(client => ({
                id: client.id,
                name: `${client.firstName} ${client.lastName}`
            }));

            console.log('Transformed clients data:', transformedClients);
            setTransformedClients(transformedClients);

            // Now you can use transformedClients in your component
            // or set it to state if needed
        }
    }, [clients]);

    const [transformedClients, setTransformedClients] = useState([]);

    const selectedClient = useMemo(() => {
        if (record?.clientId && transformedClients.length > 0) {
            return transformedClients.find(client => client.id === record.clientId) || null;
        }
        return null;
    }, [record?.clientId, transformedClients]);

    useEffect(() => {
        console.log('ClientAuthorizationStatuses:', authStatuses);
    }, [authStatuses]);

    const [saveClientAuthorization, { isLoading, isError, data, error }] = useSaveClientAuthorizationMutation();
    const { data: existingData, isLoading: isFetching } = useGetClientAuthorizationQuery(record?.authorizationId, {
        skip: !record?.authorizationId,
    });

    // Define initial values without using useMemo
    const initialValues = existingData || {
        Id: record?.id || 0,
        ClientId: record?.clientId || 0,
        ClientInsuranceId: record?.clientInsuranceId || 0,
        Code: '',
        AuthorizationNumber: record?.authorizationNumber || null,
        ProviderId: null,
        AuthorizationDate: record?.authorizationDate || null,
        ExpirationDate: record?.expirationDate || null,
        RequestDate: record?.requestDate || new Date().toISOString(),
        AuthorizationStatus: record?.authorizationStatus || null,
        AuthorizationStatusId: record?.authorizationStatusId || 1,
        DenialId: record?.denialId || null,
        Notes: record?.notes || null,
        CreatedByName: record?.createdByName || null,
        CreatedBy: record?.createdBy || null,
        CreateDate: record?.createDate || new Date().toISOString(),
        UpdatedByName: record?.updatedByName || null,
        UpdatedBy: record?.updatedBy || null,
        UpdateDate: record?.updateDate || new Date().toISOString(),
        InsuranceName: record?.insuranceName || null,
        IsDeleted: false,
        AuthorizedServices: record?.authorizedServices || [],
    };

    const defaultServiceValues = {
        id: 0,
        AuthorizationId: 0,
        ProcedureCode: '',
        Modifier: '',
        ServiceId: null,
        Rate: 0,
        Units: 0,
    };

    const createdAt = initialValues.CreateDate
        ? new Date(initialValues.CreateDate).toLocaleString()
        : "Date not available";
    //const { setFieldValue } = useFormikContext();

    useEffect(() => {
        console.log('ClientAuthorizationForm initialValues:', initialValues);
    }, [initialValues]);

    const handleSubmit = async (values) => {
        console.log('ClientAuthorizationForm values to submit:', values);

        if (JSON.stringify(values) === JSON.stringify(initialValues)) {
            console.log("No changes detected, closing form.");
            if (onClose) {
                onClose(); // Call the onClose function to close the form
            }
            return;
        }

        try {
            const result = await saveClientAuthorization(values).unwrap();
            console.log('ClientAuthorizationForm submit result:', result);
            if (onSuccess) onSuccess(result);
        } catch (err) {
            console.error("Failed to save client authorization:", err);
        }
    };

    if (isFetching) return <p>Loading...</p>;

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                    //backgroundColor: theme.palette.background.popupHeader, // Blue fill color
                    padding: '0.5em 1em',       // Add padding for spacing
                    borderRadius: '2px',        // Optional: rounded corners
                    borderBottom: '2px solid',
                    borderColor: 'divider',
                    color: theme.palette.primary.dark             // Ensure text is readable on blue background
                }}>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <ToolsImg src={history} alt="" />
                    <Typography variant="body2">
                        Created @ {initialValues.createdByName ? initialValues.createDate : createdAt}
                    </Typography>
                    <Typography variant="h6">
                        By {initialValues.createdByName || userName}
                    </Typography>
                </Box>
            </Box>
            <Formik
                initialValues={initialValues}
                //validationSchema={ClientAuthorizationSchema}
                enableReinitialize
                onSubmit={handleSubmit}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <AutocompleteFieldForFilters
                                    name={"Client"}
                                    label={"Client"}
                                    data={transformedClients}
                                    value={transformedClients.find(client => client.id === values.ClientId) || null}
                                    onChange={(selectedClient) => {
                                        setFieldValue('ClientId', selectedClient ? selectedClient.id : null);
                                    }}
                                    labelType={"subtitle2"}
                                    marginBottom="4px"
                                    disabled={values.Id !== 0}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputFieldFormik name="InsuranceName" label="Insurance Name" type="text" sx={{ width: '280px' }} />
                            </Grid>

                            <Grid item xs={values.AuthorizationStatusId === deniedStatusId || showAuthorizationNumberIds.includes(values.AuthorizationStatusId) ? 6 : 12}>
                                <SelectFieldFormik
                                    name="AuthorizationStatusId"
                                    label="Status"
                                    data={authStatuses?.map((status) => ({
                                        id: status.id, // Key for each item
                                        name: status.name, // Display text
                                    }))}
                                />
                            </Grid>
                            {showAuthorizationNumberIds.includes(values.AuthorizationStatusId) && (
                                <Grid item xs={6}>
                                    <InputFieldFormik name="AuthorizationNumber" label="Authorization Number" type="text" />
                                </Grid>
                            )}
                            {values.AuthorizationStatus === deniedStatusId && (
                                <Grid item xs={6}>
                                    <SelectFieldFormik
                                        name="DenialId"
                                        label="Denial Reason"
                                        data={denialReasons}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={4}>
                                <DatePickerFieldFormik name="RequestDate" label="Request Date" />
                            </Grid>
                            <Grid item xs={4}>
                                <DatePickerFieldFormik name="AuthorizationDate" label="Authorization Date" />
                            </Grid>
                            <Grid item xs={4}>
                                <DatePickerFieldFormik name="ExpirationDate" label="Expiration Date" />
                            </Grid>

                            <Grid item xs={12}>
                                <TextAreaFieldFormik name="Notes" label="Notes" type="text" sx={{ width: '400px', maxWidth: '900px' }} />
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{ backgroundColor: '#fafafa', padding: '1em', borderRadius: '8px' }}>
                                    <Typography variant="h6" sx={{ mb: 1.5 }}>Authorized Services</Typography>
                                    <FieldArray name="AuthorizedServices">
                                        {({ push, remove }) => (
                                            <>
                                                {values.AuthorizedServices.map((service, index) => (
                                                    <ClientAuthorizedServiceForm
                                                        key={index}
                                                        index={index}
                                                        values={values.AuthorizedServices[index]}
                                                        setFieldValue={setFieldValue}
                                                    />
                                                ))}
                                                <Button onClick={() => push({ procedureCode: "", modifier: "", serviceId: null, rate: 0, units: 0, isDeleted: false })}>
                                                    Add Service
                                                </Button>
                                            </>
                                        )}
                                    </FieldArray>
                                </Box>
                            </Grid>
                            <Grid item xs={12} >
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button type="submit" disabled={isLoading} variant="contained"
                                    //startIcon={<SaveIcon sx={{ color: 'white' }}/>}
                                    >
                                        Close
                                    </Button>
                                </Box>
                                {isLoading && <p>Saving...</p>}
                                {isError && <p>Error: {error.message}</p>}
                                {data && <p>Client authorization saved successfully!</p>}
                            </Grid>

                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default ClientAuthorizationForm;
