import React from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { PsychosocialAssessment } from "../../psychosocialAssessment/PsychosocialAssessment";
import styled from "styled-components";

const PsychosocialAssessmentPageContainer = styled.div`
  display: flex;
  max-width: 960px;
  margin-left: 0px;
`;

export const DetailPanelContent = ({
  open, // External state to control dialog visibility
  onClose, // Function to close the dialog
  propClientId,
  propFormId,
  propClientServiceId,
}) => {
  // Log the prop values for debugging
  console.log("DetailPanelContent Props:", {
    propClientId,
    propFormId,
    propClientServiceId,
  });

  return (
      <PsychosocialAssessmentPageContainer>
        <PsychosocialAssessment
          propClientId={propClientId}
          propFormId={propFormId}
          propClientServiceId={propClientServiceId}
        />
      </PsychosocialAssessmentPageContainer>

  );
};
