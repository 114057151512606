import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Button, Modal } from "@mui/material";
import DatePickerField from "../../common/DatePickerField";
import SelectField from "../../common/SelectFieldForForm";
import CheckboxField from "../../common/CheckboxField";
import InputField from "../../common/InputField";
import { ElementBody, ElementFragment, ElementHeader, PopupBody, PopupFooter } from "./ElementDetailsForm.styled";
import { Delete } from "@mui/icons-material";
import useFetchWithMsal from "../../../utils/useFetchWithMsal";
import RadioGroup from "../../common/RadioGroup";
import { PopupButton } from "../../calendar/Calendar.styled";
import CloseIcon from "@mui/icons-material/Close";


export const ElementInfoPopup = ({
  open,
  elements,
  onClose,
  index,
  setElements,
  frequency,
  routeOfIngestion,
  drugOfChoice
}) => {

  const [initialValues, setInitialValues] = useState(null);
  useEffect(() => {
    if (open) {
      setInitialValues(elements[index]); // Sync initial values when the modal opens
    }
  }, [open, index, elements]);

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [elementIndexToDelete, setElementIndexToDelete] = useState(null);
  const [unsavedChangesModalOpen, setUnsavedChangesModalOpen] = useState(false);

  const handleOpenUnsavedChangesModal = () => {
    setUnsavedChangesModalOpen(true);
  };

  const handleCloseUnsavedChangesModal = () => {
    setUnsavedChangesModalOpen(false);
  };

  const handleDiscardChanges = () => {
    setElements(prevElements => {
      const updatedElements = [...prevElements];
      updatedElements[index] = initialValues; // Revert to initial values
      return updatedElements;
    });
    handleCloseUnsavedChangesModal();
    onClose();
  };

  const handleCancelUnsavedChanges = () => {
    handleCloseUnsavedChangesModal(); // Close the modal without any action
  };

  useEffect(() => {
    if (!initialValues) {
      setInitialValues(elements[index]);
    }
  }, [index, elements]);

  // const handleCancel = () => {
  //   handleCloseUnsavedChangesModal();
  //   onClose();
  // };

  const handleCancel = () => {
    if (initialValues) {
      elements[index]?.newElement ?
        setElements(prevElements => {
          const updatedElements = [...prevElements];
          updatedElements.splice(index, 1);
          return updatedElements;
        }) :
        setElements(prevElements => {
          const updatedElements = JSON.parse(JSON.stringify(prevElements));
          updatedElements[index] = initialValues;
          return updatedElements;
        });
    }
    setInitialValues(null)
    handleCloseUnsavedChangesModal();
    onClose();
  };

  // const handlePopupButtonClick = () => {
  //   const hasUnsavedChanges = elements.some((element, i) => {
  //     return JSON.stringify(element) !== JSON.stringify(initialValues) && i === index;
  //   });
  //   if (hasUnsavedChanges) {
  //     handleOpenUnsavedChangesModal();
  //   } else {
  //     onClose();
  //   }
  // };

  const handlePopupButtonClick = () => {
    const hasUnsavedChanges = elements.some((element, i) => {
      return JSON.stringify(element) !== JSON.stringify(initialValues) && i === index;
    });

    console.log("has unsaved changes:", hasUnsavedChanges);
    if (hasUnsavedChanges) {
      handleOpenUnsavedChangesModal();
    } else {
      if (elements[index]?.newElement) {
        setElements(prevElements => {
          const updatedElements = [...prevElements];
          updatedElements.splice(index, 1); // Remove the element if it's new
          return updatedElements;
        });
      }
      setInitialValues(null); // Reset initial values
      onClose(); // Ensure the modal closes
    }
  };


  const { skip } = useFetchWithMsal();

  const [formErrors, setFormErrors] = useState({});
  const validateForm = () => {
    const errors = {};
    if (!elements[index].ageOfFirstUse) {
      errors.ageOfFirstUse = true;
    }
    if (!elements[index].dateOfLastUse) {
      errors.dateOfLastUse = true;
    }
    if (!elements[index].drugOfChoice) {
      errors.drugOfChoice = true;
    }
    if (!elements[index].frequency) {
      errors.frequency = true;
    }
    /*     if (!elements[index].qty) {
          errors.qty = true;
        } */
    if (!elements[index].routeOfIngestion) {
      errors.routeOfIngestion = true;
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSave = () => {
    if (validateForm()) {
      onClose();
    }
  };

  const getSelectData = (name) => {
    switch (name) {
      case "routeOfIngestion":
        return routeOfIngestion;
      case "frequency":
        return frequency;
      case "drugOfChoice":
        return drugOfChoice;
      default:
        return [];
    }
  };

  const handleRemoveElement = (index) => {
    setElementIndexToDelete(index);
    setConfirmDeleteOpen(true);
  };

  const confirmDelete = () => {
    if (elementIndexToDelete !== null) {
      setElements(prevElements => {
        const updatedElements = [...prevElements];
        updatedElements.splice(elementIndexToDelete, 1);
        return updatedElements;
      });
      setInitialValues(null)
      setElementIndexToDelete(null);
      onClose();
      setConfirmDeleteOpen(false);
    }
  };

  const cancelDelete = () => {
    setElementIndexToDelete(null);
    setConfirmDeleteOpen(false);
  };

  const getInputComponent = (item, index, width, height) => {
    if (elements[index] !== undefined) {
      switch (item?.type) {
        case "select":
          return (
            <SelectField
              name={item.name}
              label={item.label}
              data={getSelectData(item.name)}
              value={elements[index][item.name]}
              setValue={(name, value) => handleFieldChange(name, value, index)}
              required={item.required}
              fullWidth
            />
          );
        case "date":
          return (
            <DatePickerField
              name={item.name}
              label={item.label}
              value={elements[index][item.name]}
              required={item.required}
              setValue={(name, value) => handleFieldChange(name, value, index)}
              error={formErrors[item?.name]}
            />
          );
        case "checkbox":
          return (
            <CheckboxField
              name={item.name}
              label={item.label}
              value={elements[index][item.name]}
              required={item.required}
              setValue={(name, value) => handleFieldChange(name, value, index)}
            />
          );
        case "radio":
          return (
            <RadioGroup
              name={item.name}
              label={item.label}
              items={[{ id: 1, name: 'Continue' }, { id: 2, name: 'Discontinue' }]}
              value={elements[index][item.name]}
              required={item.required}
              setValue={(name, value) => handleFieldChange(name, value, index)}
            />
          );
        case "number":
          return (
            <InputField
              name={item?.name}
              label={item?.label}
              value={elements[index][item?.name]}
              setValue={(name, value) => item.maxLength ?
                (value.length <= item.maxLength && value >= 0) && handleFieldChange(name, Number(value), index) :
                handleFieldChange(name, Number(value), index)
              }
              fullWidth
              width={width}
              height={height}
              required={item.required}
              type='number'
              maxLength={item.maxLength}
              step={item.step}
              error={formErrors[item?.name]}
            />
          );
        default:
          return (
            <InputField
              name={item?.name}
              label={item?.label}
              value={elements[index][item?.name]}
              setValue={(name, value) => handleFieldChange(name, value, index)}
              fullWidth
              width={width}
              height={height}
              required={item.required}
              maxLength={item.maxLength}
              error={formErrors[item?.name]}
            />
          );
      }
    }
  };

  const handleFieldChange = (fieldName, value, elementIndex) => {
    setElements(prevElements => {
      const updatedHistory = [...prevElements];
      updatedHistory[elementIndex] = { ...updatedHistory[elementIndex], [fieldName]: value };
      return updatedHistory;
    });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%', // Set the width to 50% of the window width
        bgcolor: 'background.paper',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)', // Custom drop shadow
        p: 4, // Padding for the modal
        padding: '0px',
        height: '300px',
        borderRadius: 2, // Optional: Add some rounding to the corners
        maxHeight: '90%', // Ensure it doesn't overflow the screen height
        overflowY: 'auto', // Enable scrolling if content overflows
        margin: '0px',
      }}
    >
      <PopupBody >
        <ElementFragment key={index} >
          <ElementHeader>
            <Box>
              {elements[index]?.newElement ? (
                <Typography variant="h2">Add Record</Typography>
              ) : (
                <Typography variant="h2">Edit Record</Typography>
              )}
            </Box>
            <PopupButton aria-label="close" onClick={handlePopupButtonClick}>
              <CloseIcon />
            </PopupButton>
          </ElementHeader>
          <ElementBody>
            <Grid container rowSpacing="20px" columnSpacing="20px">
              <Grid item xs={4}>
                {getInputComponent(
                  {
                    name: 'drugOfChoice',
                    label: 'Drug of Choice',
                    type: 'select',
                    required: true,
                  },
                  index,
                  '400px'
                )}
              </Grid>
              <Grid item xs={4}>
                {getInputComponent(
                  {
                    name: 'frequency',
                    label: 'Frequency',
                    type: 'select',
                    required: true,
                  },
                  index,
                  '400px'
                )}
              </Grid>
              <Grid item xs={4}>
                {getInputComponent(
                  {
                    name: 'routeOfIngestion',
                    label: 'Route of Ingestion',
                    type: 'select',
                    required: true,
                  },
                  index,
                  '400px'
                )}
              </Grid>
              {/*               <Grid item xs={4}>
                {getInputComponent(
                  {
                    name: 'qty',
                    label: 'Quantity Prescribed',
                    type: 'number',
                    required: true,
                    step: '0.01',
                    maxLength: 8,
                  },
                  index
                )}
              </Grid> */}
              <Grid item xs={4}>
                {getInputComponent(
                  {
                    name: 'ageOfFirstUse',
                    label: 'Age of First Use',
                    type: 'number',
                    required: true,
                    maxLength: 2,
                  },
                  index
                )}
              </Grid>
              <Grid item xs={4}>
                {getInputComponent(
                  {
                    name: 'dateOfLastUse',
                    label: 'Date of Last Use',
                    type: 'date',
                    required: true,
                  },
                  index
                )}
              </Grid>
            </Grid>
          </ElementBody>
          <PopupFooter>
            <Button
              sx={{
                marginRight: '10px',
                width: '108px',
                height: '26px',
                borderRadius: '2px',
              }}
              variant="contained"
              onClick={handleSave}
            >
              Save
            </Button>
            <Button
              sx={{
                marginRight: '10px',
                width: '108px',
                height: '26px',
                borderRadius: '2px',
              }}
              variant="outlined"
              onClick={handlePopupButtonClick}
            >
              Close
            </Button>
            <Delete
              sx={{
                marginLeft: 'auto',
                cursor: 'pointer',
                width: '14px',
              }}
              onClick={() => handleRemoveElement(index)}
            />
            {/* Delete Confirmation Modal */}
            <Modal open={confirmDeleteOpen} onClose={cancelDelete}>
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  bgcolor: 'background.paper',
                  boxShadow: 24,
                  p: 4,
                }}
              >
                <Typography variant="h6">
                  Are you sure you want to delete this element?
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '20px',
                  }}
                >
                  <Button
                    sx={{
                      marginRight: '10px',
                      width: '108px',
                      height: '26px',
                      borderRadius: '2px',
                    }}
                    variant="outlined"
                    onClick={cancelDelete}
                  >
                    Cancel
                  </Button>
                  <Button
                    sx={{
                      marginRight: '10px',
                      width: '108px',
                      height: '26px',
                      borderRadius: '2px',
                    }}
                    variant="contained"
                    onClick={confirmDelete}
                  >
                    Delete
                  </Button>
                </Box>
              </Box>
            </Modal>
            {/* Unsaved Changes Confirmation Modal */}
            <Modal
              open={unsavedChangesModalOpen}
              onClose={handleCancelUnsavedChanges}
              aria-labelledby="unsaved-changes-title"
              aria-describedby="unsaved-changes-description"
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  bgcolor: 'background.paper',
                  boxShadow: 24,
                  p: 4,
                  borderRadius: 2,
                }}
              >
                <Typography id="unsaved-changes-title" variant="h6">
                  Unsaved Changes
                </Typography>
                <Typography id="unsaved-changes-description" sx={{ mt: 2 }}>
                  You have unsaved changes. Do you want to save them before closing?
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                  <Button
                    sx={{ width: '108px', height: '26px', borderRadius: '2px' }}
                    variant="outlined"
                    onClick={handleCancelUnsavedChanges}
                  >
                    Cancel
                  </Button>
                  <Button
                    sx={{ width: '108px', height: '26px', borderRadius: '2px' }}
                    variant="contained"
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                  <Button
                    sx={{ width: '108px', height: '26px', borderRadius: '2px' }}
                    variant="outlined"
                    onClick={handleDiscardChanges}
                  >
                    Discard
                  </Button>
                </Box>
              </Box>
            </Modal>
          </PopupFooter>
        </ElementFragment>
      </PopupBody>
    </Modal>

  );
};

