import React, { useState } from "react";
import "./SchedulePage.css";
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { BilingReport } from "../../components/bilingReport/BilingReport";
import { ServiceCalendarPicker } from "../../components/calendar/ServiceCalendarPicker";
import { Grid, Box, Chip, Tooltip, List, ListItem, ListItemText, ListItemIcon, Typography, Menu, MenuItem, Link, TextField } from '@mui/material';

export const SchedulePage = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  const [serviceDate, setServiceDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [badgeDates, setBadgeDates] = React.useState([]);

  const handleDateRangeUpdate = ([newServiceDate, newEndDate]) => {
    console.log("schedule page start", newServiceDate);
    console.log("schedule page end", newEndDate);
    setServiceDate(newServiceDate);
    setEndDate(newEndDate);
  };

  const handleBadgeDatesUpdate = (dates) => {
    setBadgeDates(dates);
  };

  return (
    <AuthenticatedTemplate>
      {activeAccount ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between', // Space between elements
            alignItems: 'flex-start', // Align to the top
            gap: 1, // Optional: Add some space between the two components
            overflow: "hidden",
          }}
        >
          {/* BillingReport on the left */}
          <Box sx={{ flex: 0.8, minWidth: '65%' }}>
            <BilingReport
              fromSource="schedule"
              externalServiceDate={serviceDate}
              externalEndDate={endDate}
            />
          </Box>

          {/* ServiceCalendarPicker on the right */}
          <Box sx={{ flex: 0.5, minWidth: '35%', marginTop: '85px' }}>
            <ServiceCalendarPicker
             onChange={handleDateRangeUpdate}
             />
          </Box>
        </Box>
      ) : null}
    </AuthenticatedTemplate>
  );
};
