import { useState, useEffect, useMemo, useCallback } from "react";
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { Grid, Box, Chip, Tooltip, List, ListItem, ListItemText, ListItemIcon, Typography, Menu, MenuItem, Link, TextField } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { Page, PDFDownloadLink } from '@react-pdf/renderer';
import InvoiceReport from '../reports/InvoiceReport';
import { useNavigate } from "react-router-dom";
import ClientAuthorizationForm from '../authorization/ClientAuthorizationForm';
import { ServiceCalendarPicker } from "../calendar/ServiceCalendarPicker";
import { visuallyHidden } from '@mui/utils';
import { GridToolbar } from '@mui/x-data-grid';
import {
  ButtonContainer,
  ButtonsContainer,
  ClientsTitleContainer,
  EmptyMessage,
  HeaderContainer,
  ResultData,
  TableControlsContainer,
  TableSearchContainer,
  ToolsImg,
  UploadFileContainer,
  StyledButton,
  FilterChip,
  FilterChipList,
  CrossImg,
  dateFieldStyle,
} from './AuthorizationsReport.styled';
import {
  useDownloadFile837Mutation,
  useGetBillingReportMutation,
  useGetAuthorizationReportMutation,
  usePostUpload835FileMutation,
  useGetClientServiceBillingHistoryQuery,
  useGetAssignmentClientsMutation,
  useGetAuthorizationListQuery,
  useGetBillingStatusQuery,
  useGetLevelOfCareQuery,
  useGetServicesQuery,
  useSendFile837Mutation,
  useGetERAFilesMutation,
  useDownloadFile278Mutation,
  useGetAuthorizationStatusesQuery,
} from '../../services/ClientService';
import styled from '@emotion/styled';
import moment from 'moment';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { LoadingPanel } from '../../shared/components/LoadingPanel';
import useFetchWithMsal from '../../utils/useFetchWithMsal';
import { AuthorizationReportFilter } from "./AuthorizationReportFilter";
import { AuthStatusHistoryStepper } from "./AuthStatusHistoryStepper";
//import BillingEditModal from "./BillingEditModal";
//import BillingStatusTotals from './BillingStatusTotals';
import exportIcon from "../../shared/icons/export.svg";
import { CustomButton } from "../table/TableTab";
import { FileUpload } from "../common/FileUpload";
import { UploadFile } from "@mui/icons-material";
import ErrorAlert from "../../shared/components/ErrorAlert";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "../../store/slices/ErrorSlice";
//import { BillingStatusSelect } from "./BillingStatusSelect";
import { IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, Popover, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AlarmIcon from '@mui/icons-material/Alarm';
import DateRangeIcon from '@mui/icons-material/DateRange';
import list from "../../shared/icons/list.svg";
import history from "../../shared/icons/history.svg";
import crossSvg from "../../shared/icons/cross.svg";
import eyeOpen from "../../shared/icons/eyeOpen.svg";
import { BsThreeDots } from 'react-icons/bs';
import { FaHistory, FaUpload, FaHourglassHalf, FaCheckCircle, FaTimesCircle, FaBan, FaTrashAlt, FaRedoAlt, FaDollarSign, FaFileAlt, FaSyncAlt, FaEraser, FaReceipt, FaInfo, FaEdit, FaFilter, FaPaperPlane } from 'react-icons/fa';
import FilterListIcon from "@mui/icons-material/FilterList";
import { setBillingStatus, setService, setClient, setLevelofCare, setAuthStatus, setAuthorizationStatus } from '../../store/slices/FilterSlice';
import DatePickerField from '../common/DatePickerField';
import DatePickerFieldNonClearable from "../common/DatePickerFieldNonClearable";
import ImageIcon from '@mui/icons-material/Image';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import DeleteIcon from '@mui/icons-material/Delete';
import BlockIcon from '@mui/icons-material/Block';
import RedoIcon from '@mui/icons-material/Redo';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DescriptionIcon from '@mui/icons-material/Description';
import SyncIcon from '@mui/icons-material/Sync';
import ErrorIcon from '@mui/icons-material/Error';
import HistoryIcon from '@mui/icons-material/History';
import BackupIcon from "@mui/icons-material/Backup";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import SendIcon from "@mui/icons-material/Send";
import PaidIcon from "@mui/icons-material/Paid";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl"
import PriceCheckIcon from "@mui/icons-material/PriceCheck"
import ConfirmProcessingDialog from './authorizationDialogs/AuthConfirmProcessingDialog';
import dayjs from 'dayjs';

const StyledPaper = styled(Paper)(() => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
}));

const StyledTableHead = styled(TableHead)(() => ({
  th: {
    whiteSpace: 'nowrap',
    background: '#fff',
    color: '#4281AB',
    paddingTop: '4px',
    paddingBottom: '4px',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: '600',
    borderBottom: "1px solid #b2cede",
  },
  '&::after': {
    height: '8px',
    display: 'table-row',
    content: '""'
  }
}));

const StyledTableBody = styled(TableBody)(({ theme }) => ({
  td: {
    color: '#000',
    paddingTop: '15px',
    paddingBottom: '15px',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: '400',
  },
  th: {
    paddingTop: '15px',
    paddingBottom: '15px',
    minWidth: '120px',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: '400',
    a: {
      color: theme.palette.background.main,
      textDecoration: 'none',
    }
  },
  tr: {
    background: '#fff',
    boxShadow: '0px 1px 2px 0px #00000040',
  }
}));

const StyledPagination = styled(TablePagination)(({ theme }) => ({
  '& .MuiInputBase-root': {
    background: theme.palette.background.mainBlue
  }
}));

function stableSort(array) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  return stabilizedThis?.map((el) => el[0]);
}

const authStatusIcons = {
  'Submitted': <HourglassEmptyIcon fontSize="small" style={{ marginRight: '8px', color: 'gray' }} />,
  'Write-Off': <DeleteIcon fontSize="small" style={{ marginRight: '8px', color: 'gray' }} />,
  'Void': <BlockIcon fontSize="small" style={{ marginRight: '8px', color: 'gray' }} />,
  'Denied': <CancelIcon fontSize="small" style={{ marginRight: '8px', color: 'gray' }} />,
  'Adjusted': <RedoIcon fontSize="small" style={{ marginRight: '8px', color: 'gray' }} />,
  'Partial payment': <AttachMoneyIcon fontSize="small" style={{ marginRight: '8px', color: 'gray' }} />,
  'Approved': <CheckCircleIcon fontSize="small" style={{ marginRight: '8px', color: 'green' }} />,
  'Denied': <CancelIcon fontSize="small" style={{ marginRight: '8px', color: 'gray' }} />,
  'Pending Submission': <DescriptionIcon fontSize="small" style={{ marginRight: '8px', color: 'gray' }} />,
  'Expiring': <SyncIcon fontSize="small" style={{ marginRight: '8px', color: 'gray' }} />,
  'Error': <ErrorIcon fontSize="small" style={{ marginRight: '8px', color: 'red' }} />,
};

const authStatusColors = {
  'Submitted': '#e0f0ff',
  'Write-Off': '#e0e0e0',
  'Void': '#ffe0e0',
  'Denied': '#ffe0e0',
  'Adjusted': '#f0f0e0',
  'Partial payment': '#e0ffe0',
  'Approved': '#e0ffe0',
  'Denied': '#ffe0e0',
  'Pending Submission': '#f0f0f0',
  'Expiring': '#e0f0ff',
  'Error': '#ffe0e0',
};

const CustomLoadingOverlay = () => (
  <Box
    sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(255, 255, 255, 0.7)', // Optional: for a semi-transparent background
      zIndex: 1,
    }}
  >
    <CircularProgress />
  </Box>
);

const headCells = [
  {
    id: 'clientFirstName',
    numeric: false,
    disablePadding: false,
    label: 'Client',
    sortable: true,
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Service',
    sortable: true,
  },
  {
    id: 'levelOfCare',
    numeric: false,
    disablePadding: false,
    label: 'Level Of Care',
    sortable: true,
  },
  {
    id: 'user',
    numeric: false,
    disablePadding: false,
    label: 'Provider',
    sortable: true,
  },
  {
    id: 'serviceDate',
    numeric: false,
    disablePadding: false,
    label: 'Service Date',
    sortable: true,
  },
  {
    id: 'startTime',
    numeric: false,
    disablePadding: true,
    label: 'Start Time',
    sortable: false
  },
  {
    id: 'endTime',
    numeric: false,
    disablePadding: true,
    label: 'End Time',
    sortable: false
  },
  {
    id: 'billingStatus',
    numeric: false,
    disablePadding: false,
    label: 'Billing Status',
    sortable: true,
  },
  {
    id: 'billingHistory',
    numeric: false,
    disablePadding: false,
    label: '',
    sortable: false,
  },
  {
    id: 'rate',
    numeric: false,
    disablePadding: false,
    label: 'Rate',
    sortable: true,
    align: 'right',
  },
  {
    id: 'totalPayment',
    numeric: false,
    disablePadding: false,
    label: 'Payment',
    sortable: true,
    align: 'right',
  }, {
    id: 'buttons',
    numeric: false,
    disablePadding: false,
    label: ' ',
    sortable: true,
  },
];

const CustomToolbar = () => (
  <GridToolbar showQuickFilter={false} quickFilterProps={{ debounceMs: 500 }} />
);

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <StyledTableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align || (headCell.numeric ? 'right' : 'left')}  // Default to left if no align specified
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={headCell.sortable ? createSortHandler(headCell.id) : undefined}
            >
              {headCell.label}
              {headCell.sortable && orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>

        ))}
      </TableRow>
    </StyledTableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const getNameById = (id, data) => {
  console.log('getNameById id:', id);
  console.log('getNameById data:', data);
  const item = data?.find((entry) => entry.id === id);
  return item ? item.name : "Unknown Status"; // Default to "Unknown Status" if not found
};


export const AuthorizationsReport = (props) => {
  const { externalServiceDate, externalEndDate, fromSource, initialBillingStatusId, initialClientId } = props;
  const constWidth = fromSource === "schedule" ? 50 : 100;
  const constDisplay = fromSource === "schedule" ? 'inline-block' : 'flex';
  //const constDisplay = 'inline-block';
  const { skip, setSkip } = useFetchWithMsal();
  const dispatch = useDispatch();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  //tree view
  //const [isTreeView, setIsTreeView] = useState(true);
  const [isTreeView, setIsTreeView] = useState(fromSource !== "schedule");

  //calendar fitlers
  const [serviceDate, setServiceDate] = useState(dayjs().subtract(30, 'day'));
  const [endDate, setEndDate] = useState(dayjs());
  const [serviceDateRange, setServiceDateRange] = useState([dayjs().subtract(30, 'day'), dayjs()]);

  const [clientIdList, setClientIdList] = useState([]);
  //const [service, setService] = useState([]);
  const [authorizationList, setAuthorizationList] = useState([]);
  //const [authorizationstatus, setAuthorizationStatus] = useState([]);
  //const [levelOfCare, setLevelOfCare] = useState([]);
  //const [billingStatus, setBillingStatus] = useState([1]);
  const [fileValue835, setFileValue835] = useState(null);
  const [uploadMessage, setUploadMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [serviceName, setServiceName] = useState(0);
  const [uploadSuccessful, setUploadSuccessful] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isHistoryDialogOpen, setIsHistoryDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isBillingToolsOpen, setIsBillingToolsOpen] = useState(false);
  const [isAuthToolsOpen, setIsAuthToolsOpen] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [unbilledAndResubmitCount, setUnbilledAndResubmitCount] = useState(0);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [dialogAction, setDialogAction] = useState(() => null);

  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const isFilterPopoverOpen = Boolean(filterAnchorEl);

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'billing-history-popover' : undefined;
  const serviceId = selectedRecord?.id;

  const [isAuthorizationDialogOpen, setIsAuthorizationDialogOpen] = useState(false);
  const [authorizationId, setAuthorizationId] = useState(null);
  const [clientId, setClientId] = useState(null);

  /*   useEffect(() => {
      // This will be triggered only once after the component mounts
      setSkip(false);
    }, []); // Empty dependency array to make this effect run only once */



  const [getClients, { data: clients }] = useGetAssignmentClientsMutation();
  useEffect(() => {
    const requestData = {
      startDate: null,
      endDate: null,
      isShowAll: true,
    };
    console.log("Requesting clients with data:", requestData);

    // Make the request
    getClients(requestData);
  }, []);

  useEffect(() => {
    if (clients) {
      // Transform the data to an array of { id, name } objects
      const transformedClients = clients.map(client => ({
        id: client.id,
        name: `${client.firstName} ${client.lastName}`
      }));

      console.log('Transformed clients data:', transformedClients);
      setTransformedClients(transformedClients);

      // Now you can use transformedClients in your component
      // or set it to state if needed
    }
  }, [clients]);
  const [transformedClients, setTransformedClients] = useState([]);

  useEffect(() => {
    if (clients) {
      console.log("Received clients data:", clients);
    } else {
      console.log("No clients data received yet.");
    }
  }, [clients]);

  useEffect(() => {
    if (fromSource) {
      console.log("fromSource:", fromSource);
    }
  }, { skip });

  useEffect(() => {
    // Update isTreeView when fromSource changes
    setIsTreeView(fromSource === "schedule");
  }, [fromSource]);

  // Handler for when the date range changes
  const handleDateRangeChange = (newRange) => {
    const [start, end] = newRange;
    setServiceDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    // Only trigger if both values are not null and have changed
    if (
      externalServiceDate &&
      externalEndDate &&
      (externalServiceDate !== serviceDate || externalEndDate !== endDate)
    ) {
      console.log("Service Date:", externalServiceDate);
      console.log("End Date:", externalEndDate);

      setServiceDate(externalServiceDate);
      setEndDate(externalEndDate);

      // Fetch or update data based on new date range
    }
  }, [externalServiceDate, externalEndDate, serviceDate, endDate]);

  const { data: services } = useGetServicesQuery({ skip });
  const { data: levelofcares } = useGetLevelOfCareQuery({ skip });
  const { data: billingstatuses } = useGetBillingStatusQuery({ skip });
  const { data: authorizationstatuses } = useGetAuthorizationStatusesQuery({ skip });
  const { data: authstatuses } = useGetAuthorizationListQuery({ skip });
  const { billingstatus, client, service, levelofcare, authstatus, authorizationstatus } = useSelector(
    (state) => state.filter
  );
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const navigate = useNavigate();

  // Set default billing status to 1 on initial load
  useEffect(() => {
    // Set default billing status to [1] if not already set

    if (fromSource === "authorization" && (!authstatus || authstatus.length === 0)) {
      console.log("initial authorization status id", authstatus)
      dispatch(setAuthStatus([1]));
      setIsInitialLoad(false);
    } else if (fromSource === "client" && !client) {
      console.log("initial client id", initialClientId)
      dispatch(setClient([initialClientId]));
      setIsInitialLoad(false);
    } else {
      // Trigger `getAuthoriationReport` on initial load or when filters change
      getAuthorizationReport({
        recordNumber: page * rowsPerPage,
        offset: rowsPerPage,
        requestDate: serviceDate,
        requestEndDate: endDate,
        clientIdList: Array.isArray(client) ? client : client ? [client] : [],
        serviceIdList: Array.isArray(service) ? service : service ? [service] : [],
        levelOfCare: Array.isArray(levelofcare) ? levelofcare : levelofcare ? [levelofcare] : [],
        authorizationStatus: Array.isArray(authorizationstatus) ? authorizationstatus : authorizationstatus ? [authorizationstatus] : [],
        authorizationList: Array.isArray(authstatus) ? authstatus : authstatus ? [authstatus] : [],
        order,
        orderBy: replaceName(orderBy),
      });
      console.log("authorization report results")
    }

  }, [dispatch, skip]); // Dependencies to re-trigger when filters change

  /*   useEffect(() => {
      if (!skip) {
        getClients();
      }
    }, [skip, getClients]); */

  useEffect(() => {
    return () => {
      dispatch(setBillingStatus(null));
      dispatch(setClient(null));
      dispatch(setService(null));
      dispatch(setLevelofCare(null));
      dispatch(setAuthStatus(null));
      dispatch(setAuthorizationStatus(null));
    };
  }, [dispatch]);

  const handleFilterDialogOpen = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterDialogClose = () => {
    setFilterAnchorEl(null);
  };

  const columns = [
    {
      field: 'clientName',
      headerName: 'Client',
      width: 200,
      renderCell: (params) => (
        <Box
          sx={{
            marginTop: '12px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start', // Align content to the left
            justifyContent: 'flex-start', // Align content to the top
            lineHeight: '1.2',
          }}
        >
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault(); // Prevents default link behavior
              handleClientClick(params.row.name, params.row.clientId);
            }}
            style={{ cursor: 'pointer', textDecoration: 'underline' }} // Optional styling
          >
            {params.row.clientName}
          </a>
          <span style={{ fontSize: '0.85em', color: '#666' }}>{params.row.levelOfCare}</span> {/* Second line with procedureCode */}
        </Box>
      )
    },
    {
      field: 'insuranceName',
      headerName: 'Insurance',
      width: 200,
      renderCell: (params) => (
        <Typography variant="body2"
          sx={{
            marginTop: '12px',
          }}
        >
          {params.row.insuranceName || 'N/A'}
        </Typography>
      ),
    },
    {
      field: 'requestDate',
      headerName: 'Request Date',
      width: 150,
      renderCell: (params) => (
        <Typography variant="body2"
          sx={{
            marginTop: '12px',
          }}
        >
          {params.row.createDate
            ? moment(params.row.createDate).format('MM/DD/YYYY')
            : 'N/A'}
        </Typography>
      ),
    },
    {
      field: 'createdByName',
      headerName: 'Requested By',
      width: 150,
      renderCell: (params) => (
        <Typography variant="body2"
          sx={{
            marginTop: '12px',
          }}
        >
          {params.row.createdByName || 'N/A'}
        </Typography>
      ),
    },
    {
      field: 'authorizationStatus',
      headerName: 'Status',
      width: 225, // Increase the width to accommodate both components
      sortable: false, // Disable sorting
      resizable: false, // Disable column resizing
      renderCell: (params) => {
        const status = params.row.authorizationStatus || '';
        const limitErrors = params.row.billingLimitErrors || 'None';
        const controlErrors = params.row.billingControlErrors || 'None';
        const icon = authStatusIcons[status];
        const backgroundColor = authStatusColors[status] || 'white'; // Default to white if status not found

        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              backgroundColor: backgroundColor,
              padding: '0px', // Add some padding for better spacing
              borderRadius: '4px', // Optional: for rounded corners
            }}
          >
            <Box sx={{ paddingLeft: '8px', display: 'flex', alignItems: 'center' }}>
              {icon}
              <span>{status}</span>
              {status === 'Error' && (
                <Tooltip
                  title={
                    <List>
                      {limitErrors != 'None' && (
                        <ListItem>
                          <ListItemIcon>
                            <ErrorOutlineIcon sx={{ color: 'lightgrey' }} />
                          </ListItemIcon>
                          <Typography variant="body2">Limit Error: {limitErrors}</Typography>
                        </ListItem>
                      )}
                      {controlErrors != 'None' && (
                        <ListItem>
                          <ListItemIcon>
                            <WarningAmberIcon sx={{ color: 'lightgrey' }} />
                          </ListItemIcon>
                          <Typography variant="body2">Control Error: {controlErrors}</Typography>
                        </ListItem>
                      )}
                    </List>
                  }
                >
                  <IconButton sx={{ paddingLeft: '4px' }}>
                    <MoreHorizIcon sx={{ color: 'grey' }} />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
            {status !== '' && (<IconButton onClick={(event) => handleOpenHistoryDialog(event, params.row)}>
              <HistoryIcon fontSize="small" />
            </IconButton>)}
          </Box>
        );

      },
    },
    {
      field: 'authorizationDate',
      headerName: 'Authorization Date',
      width: 150,
      renderCell: (params) => (
        <Typography variant="body2"
          sx={{
            marginTop: '12px',
          }}
        >
          {params.row.authorizationDate
            ? moment(params.row.authorizationDate).format('MM/DD/YYYY')
            : ''}
        </Typography>
      ),
    },
    {
      field: 'authorizationNumber',
      headerName: 'Number',
      width: 150,
      renderCell: (params) => (
        <Typography variant="body2"
          sx={{
            marginTop: '12px',
          }}
        >
          {params.row.authorizationNumber || ''}
        </Typography>
      ),
    },
    {
      field: 'expirationDate',
      headerName: 'Expiration Date',
      width: 150,
      renderCell: (params) => {
        const expirationDate = params.row.expirationDate;
        const isNearExpiry =
          expirationDate && moment(expirationDate).isBefore(moment().add(7, 'days')) && moment(expirationDate).isSameOrAfter(moment());
        const isPastExpiry = expirationDate && moment(expirationDate).isBefore(moment());

        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginTop: '12px' }}>
            {(isNearExpiry || isPastExpiry) && (
              <WarningAmberIcon
                fontSize="small"
                sx={{
                  color: isPastExpiry ? 'red' : 'orange', // Red for expired, orange for near expiry
                }}
              />
            )}
            <Typography
              variant="body2"
              color={isPastExpiry ? 'error' : 'inherit'} // Red text if past expiration
            >
              {expirationDate ? moment(expirationDate).format('MM/DD/YYYY') : ''}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'updateDate',
      headerName: 'Last Updated',
      width: 120,
      renderCell: (params) => (
        <Typography variant="body2"
          sx={{
            marginTop: '12px',
          }}
        >
          {params.row.updateDate
            ? moment(params.row.updateDate).format('MM/DD/YYYY')
            : ''}
        </Typography>
      ),
    },
    {
      field: 'calcAuthorizedServices',
      headerName: 'Services',
      width: 200,
      renderCell: (params) => (
        <Typography variant="body2"
          sx={{
            marginTop: '12px',
          }}
        >
          {params.row.calcAuthorizedServices}
        </Typography>
      ),
    },
    fromSource === "authorization" && {
      field: 'buttons',
      headerName: ' ',
      sortable: false, // Disable sorting
      resizable: false, // Disable column resizing
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 75,
      renderCell: (params) => (
        <div>
          <PDFDownloadLink
            document={<InvoiceReport data={params.row} />}
            fileName="invoice.pdf"
          >
            <IconButton aria-label="download" color="primary">
              <FaReceipt style={{ fontSize: '16px' }} />
            </IconButton>
          </PDFDownloadLink>

          {/* Additional Edit Icon Button */}
          <IconButton
            aria-label="edit"
            color="primary"
            onClick={(event) => handleAuthorizationDialogOpen(params.row)}
          >
            <FaEdit style={{ fontSize: '16px' }} />
          </IconButton>
        </div>
      ),
    }
  ];


  // Handle edit action
  const handleOpenEditModal = (event, row) => {
    setSelectedRecord(row);
    setAnchorEl(event.currentTarget);
    setIsEditModalOpen(true);
  };

  const handleOpenHistoryDialog = (event, record) => {
    setSelectedRecord(record);
    setAnchorEl(event.currentTarget);
    setIsHistoryDialogOpen(true);
  };


  const handleOpenDialog = (event, record) => {
    setAnchorEl(event.currentTarget);
    setSelectedRecord(record);
  };

  const handleCloseDialog = () => {
    setAnchorEl(null);
    setSelectedRecord(null);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedRecord(null);
    setAnchorEl(null);
  };

  const handleCloseHistoryDialog = () => {
    setIsHistoryDialogOpen(false);
    setSelectedRecord(null);
    setAnchorEl(null);
  };

  const handleBillingToolsClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsBillingToolsOpen(true)
  };

  const handleBillingToolsClose = () => {
    setAnchorEl(null);
    setIsBillingToolsOpen(false)
  };

  const handleAuthToolsClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsAuthToolsOpen(true)
  };

  const handleAuthToolsClose = () => {
    setAnchorEl(null);
    setIsAuthToolsOpen(false)
  };


  const handleSave = (updatedRecord) => {
    // Implement your save logic here, e.g., updating the state or making an API call
    console.log('Record saved:', updatedRecord);
  };

  const handleServiceNameChange = (event) => {
    setServiceName(event.target.value);
  };

  const [getAuthorizationReport, { data, isLoading: isGetBillingHistoryLoading }] = useGetAuthorizationReportMutation({
    recordNumber: page * rowsPerPage,
    offset: rowsPerPage,
    requestDate: serviceDate,
    requestEndDate: endDate,
    clientIdList: (Array.isArray(client) ? client : client ? [client] : []),
    serviceIdList: (Array.isArray(service) ? service : service ? [service] : []),
    levelOfCare: (Array.isArray(levelofcare) ? levelofcare : levelofcare ? [levelofcare] : []),
    authorizationStatus: (Array.isArray(authorizationstatus) ? authorizationstatus : authorizationstatus ? [authorizationstatus] : []),
    authorizationList: (Array.isArray(authstatus) ? authstatus : authstatus ? [authstatus] : []),
  }, { skip, serviceDate, endDate })

  useEffect(() => {
    if (data) {
      console.log("Authorization Report Data:", data); // Logs the data returned by the API
      if (data.authorizations) {
        console.log("Rows to set in DataGrid:", data.authorizations); // Logs the rows specifically
        setRows(data.authorizations);
      } else {
        console.log("No authorizations data found in the response.");
      }
    } else {
      console.log("No data returned yet.");
    }

    if (data?.totalCount) {
      setCount(data.totalCount);
    }
  }, [data]);


  const [downloadFile278, { data: file278, isLoading: is278FileLoading }] = useDownloadFile278Mutation({
    recordNumber: page * rowsPerPage,
    offset: rowsPerPage,
    serviceDate: serviceDate,
    endDate: endDate,
    clientIdList: Array.isArray(client) ? client : client ? [client] : [],
    serviceIdList: Array.isArray(service) ? service : service ? [service] : [],
    levelOfCare: Array.isArray(levelofcare) ? levelofcare : levelofcare ? [levelofcare] : [],
    authorizationStatus: Array.isArray(authorizationstatus) ? authorizationstatus : authorizationstatus ? [authorizationstatus] : [],
    authorizationList: Array.isArray(authstatus) ? authstatus : authstatus ? [authstatus] : [],
  }, { skip })


  const {
    data: billingHistory = [],
    error,
    isLoading,
  } = useGetClientServiceBillingHistoryQuery(
    openPopover && serviceId ? { serviceId } : skipToken
  );

  let originalRows = data?.serviceRate;

  const [rows, setRows] = useState([]);

  /*   // Prepare grouped rows for tree view
    const transformedRows = useMemo(() => {
      if (!isTreeView) {
        // Format the date for standard view as well
        return rows.map((row) => ({
          ...row,
          serviceDate: row.serviceDate
            ? moment(row.serviceDate).format("M/D/YYYY h:mm A")
            : "No Date",
        }));
      }
   
      const groupedRows = [];
      rows.forEach((row) => {
        const formattedServiceDate = row.serviceDate
          ? moment(row.serviceDate).format("M/D/YYYY h:mm A")
          : "No Date";
   
        // Find or create the group row
        let groupRow = groupedRows.find((group) => group.id === formattedServiceDate);
        if (!groupRow) {
          groupRow = {
            id: formattedServiceDate,
            serviceDate: formattedServiceDate,
            isGroup: true,
            client: `Group for ${formattedServiceDate}`, // Placeholder for grouped row display
          };
          groupedRows.push(groupRow);
        }
   
        // Add the current row with a parentId linking it to the group
        groupedRows.push({
          ...row,
          parentId: formattedServiceDate,
          serviceDate: formattedServiceDate, // Ensure consistent formatting
        });
      });
   
      return groupedRows;
    }, [rows, isTreeView]); */

  const dynamicColumns = useMemo(() => {
    if (!isTreeView) return columns;

    return columns;
  }, [columns, isTreeView]);

   
  useEffect(() => {
    if (originalRows) {
      setRows(originalRows)
    }
    if (data?.totalCount) {
      setCount(data?.totalCount)
    }
  }, [data, originalRows]);

  const replaceName = (name) => {
    if (name === 'clientFirstName') {
      return 'fullName';
    } else {
      return name;
    }
  }

  // Timer effect to automatically close the alert
  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        setOpen(false);
      }, 3000); // 5 seconds

      // Clean up the timer when the component unmounts or when `open` changes
      return () => clearTimeout(timer);
    }
  }, [open]);

  const handleDownload278File = () => {
    const transformedOrderBy = replaceName(orderBy);
    downloadFile278({
      recordNumber: page * rowsPerPage,
      offset: rowsPerPage,
      serviceDate: serviceDate,
      endDate: endDate,
      clientIdList: Array.isArray(client) ? client : client ? [client] : [],
      serviceIdList: Array.isArray(service) ? service : service ? [service] : [],
      levelOfCare: Array.isArray(levelofcare) ? levelofcare : levelofcare ? [levelofcare] : [],
      authorizationStatus: Array.isArray(authorizationstatus) ? authorizationstatus : authorizationstatus ? [authorizationstatus] : [],
      authorizationList: Array.isArray(authstatus) ? authstatus : authstatus ? [authstatus] : [],
      order,
      orderBy: transformedOrderBy,
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    console.log("changePage", newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = useMemo(
    () =>
      stableSort(rows).slice(),
    [order, orderBy, page, rowsPerPage, rows, data, originalRows],
  );

  const extractIds = array => {
    return array?.map(item => item?.id);
  }

  const handleServiceDateChange = (name, date) => {
    console.log("handleServiceDateChange", date);
    setServiceDate(date);

  };

  useEffect(() => {
    onApplyFilters('');
  }, [serviceDate, endDate]);

  const handleEndDateChange = (name, date) => {
    setEndDate(date);

  };

  const handleClientIdChange = (name, value) => {
    console.log("client id change:", value);
    setClientIdList(extractIds(value));
  };

  const handleServiceChange = (name, value) => {
    setService(extractIds(value));
  };

  const handleLevelOfCareChange = (name, value) => {
    setLevelofCare(extractIds(value));
  };

  const handleAuthorizationListChange = (name, value) => {
    setAuthorizationList(extractIds(value));
  };

  const handleAuthorizationStatusChange = (name, value) => {
    setAuthorizationStatus(extractIds(value));
  };

  const handleBillingStatusChange = (name, value) => {
    setBillingStatus(extractIds(value));
  };

  const handleDownload835File = (name, value) => {
    setFileValue835(value);
  };

  const handleServiceClick = (name, value) => {

    window.open(`#/${value}`, '_blank');

  }

  const handleClientClick = (name, value) => {

    window.open(`#/Client/${value}`, '_blank');

  }

  const [sendFile837, { isLoading: isSendingFile837 }] = useSendFile837Mutation();
  const [getERAFiles, { isLoading: isGettingERAFiles }] = useGetERAFilesMutation();

  const handleConfirmDialogOpen = (dialogType) => {
    setIsBillingToolsOpen(false);

    // Set dynamic title and content based on dialogType
    if (dialogType === "Send837") {
      setTitleText("Send File Confirmation");
      setContentText(
        unbilledAndResubmitCount === 0
          ? "No records to send!"
          : `There are ${unbilledAndResubmitCount} items with "Un-Billed" or "Re-Submitted" status. Are you sure you want to send the 837 file?`
      );
      //setDialogAction(() => handleSend837File); // Pass the specific action
    } else if (dialogType === "GetERA") {
      setTitleText("ERA Files");
      setContentText("Check For ERA Files?");
      //setDialogAction(() => handleGetERAFiles); // Pass the specific action
    } else {
      console.warn(`Unknown dialogType: ${dialogType}`);
      return;
    }

    // Open the confirmation dialog
    setIsConfirmDialogOpen(true);
  };

  useEffect(() => {
    const count = rows.filter(
      (row) => row.billingStatus === "Un-Billed" || row.billingStatus === "Resubmit"
    ).length;
    setUnbilledAndResubmitCount(count);
  }, [rows]);


  const handleDialogResponse = (response) => {

    if (response === 'close') {
      setDialogMessage(null);
      console.log("set dialog message to null", dialogMessage)
    }
  };

  const handleERADialogResponse = (response) => {

    if (response === 'close') {
      setDialogMessage(null);
      console.log("set dialog message to null", dialogMessage)
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    dispatch(setError(""));
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleRefreshData = () => {

    setSkip(true);  // Temporarily set skip to true to "reset" the fetching condition
    setTimeout(() => setSkip(false), 0);  // Set skip to false immediately after to trigger the fetch
  };



  const onApplyFilters = (resetFilter) => {

    const transformedOrderBy = replaceName(orderBy);
    console.log("start date", serviceDate);
    console.log("end date", endDate);
    console.log("client to set", client);
    console.log("authorization status to set", authorizationstatus);
    getAuthorizationReport({
      recordNumber: page * rowsPerPage,
      offset: rowsPerPage,
      requestDate: serviceDate,
      requestEndDate: endDate,
      clientIdList: resetFilter === 'client' ? [] : (Array.isArray(client) ? client : client ? [client] : []),
      serviceIdList: resetFilter === 'service' ? [] : (Array.isArray(service) ? service : service ? [service] : []),
      levelOfCare: resetFilter === 'levelofcare' ? [] : (Array.isArray(levelofcare) ? levelofcare : levelofcare ? [levelofcare] : []),
      authorizationStatus: resetFilter === 'authorizationstatus' ? [] : (Array.isArray(authorizationstatus) ? authorizationstatus : authorizationstatus ? [authorizationstatus] : []),
      authorizationList: resetFilter === 'authstatus' ? [] : (Array.isArray(authstatus) ? authstatus : authstatus ? [authstatus] : []),
      order,
      orderBy: transformedOrderBy,
    });
  };

  const onResetFilters = () => {
    getAuthorizationReport({
      recordNumber: page * rowsPerPage,
      offset: rowsPerPage,
      requestDate: serviceDate,
      requestEndDate: endDate,
    })
  };

  const onResetBillingStatus = () => {
    dispatch(setBillingStatus(""));
    onApplyFilters('billingstatus')
  };

  const onResetClient = () => {
    dispatch(setClient(""));
    onApplyFilters('client')
  };

  const onResetService = () => {
    dispatch(setService(""));
    onApplyFilters('service')
  };

  const onResetLevelofCare = () => {
    dispatch(setLevelofCare(""));
    onApplyFilters('levelofcare')
  };

  const onResetAuthStatus = () => {
    dispatch(setAuthStatus(""));
    onApplyFilters('authstatus')
  };

  const onResetAuthorizationStatus = () => {
    dispatch(setAuthorizationStatus(""));
    onApplyFilters('authorizationstatus')
  };

  /*   const memoizedBillingStatusChips = useMemo(() => {
      if (billingStatuses && billingStatus.length > 0) {
        return billingStatus.map((id) => (
          <Chip
            key={id}
            label={`Status: ${billingStatuses[id - 1]?.name}`}
            onDelete={() => handleChipDelete('Billing Status', id)}
          />
        ));
      }
      return [];
    }, [billingStatuses, billingStatus]); */

  const handleAuthorizationDialogOpen = (row) => {

    console.log("Selected Record", row);
    setSelectedRecord(row || {}); 
    setIsAuthorizationDialogOpen(true);
  };

  const handleAuthorizationDialogClose = () => {
    setIsAuthorizationDialogOpen(false);
    setSelectedRecord(null);
  };


  return (
    <Box
      sx={
        fromSource !== "client"
          ? { width: '100%', maxWidth: "calc(100% - 60px)", margin: "37px 30px" }
          : {}
      }>
      {(isGetBillingHistoryLoading) && <LoadingPanel />}
      <StyledPaper sx={{ width: '100%', mb: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #aac8db",
            margin: "0 5px",
            marginBottom: "15px",
            //flexDirection: fromSource === "schedule" ? 'column' : 'row',
          }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              //flexDirection: fromSource === "schedule" ? 'column' : 'row',
              //display: constDisplay,
              gap: 1,
            }}
          >
            {fromSource === "authorization" && (
              <ClientsTitleContainer>
                Authorizations
              </ClientsTitleContainer>
            )}
            <AuthorizationReportFilter
              onApplyFilters={onApplyFilters}
              onResetFilters={onResetFilters}
              filterSet={fromSource}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateRangePicker
                calendars={2}
                value={[serviceDate, endDate]}
                onChange={handleDateRangeChange}
                startText="Service Date"
                endText="End Date"
                renderInput={(startProps, endProps) => (
                  <>
                    <TextField {...startProps} sx={{ mr: fromSource === "schedule" ? 0 : 1, mb: fromSource === "schedule" ? 2 : 0 }} />
                    <TextField {...endProps} />
                  </>
                )}
              />
            </LocalizationProvider>

            {/* {fromSource === "schedule" && (
              <ServiceCalendarPicker
                serviceDate={serviceDate}
                endDate={endDate}
                onChange={handleDateRangeChange}
              />
            )} */}
          </Box>
          {fromSource === "authorization" && (<TableControlsContainer>
            {/*             <CustomButton
              variant="outlined"
              onClick={handleBillingToolsClick}>
              <PaidIcon style={{ size: 'sm', marginRight: '8px' }} />
              Billing Tools
            </CustomButton> */}
            <CustomButton
              variant="outlined"
              onClick={() => handleAuthorizationDialogOpen(null)}>
              <AddCircleOutlineIcon style={{ size: 'sm', marginRight: '8px' }} />
              Create New
            </CustomButton>
            <CustomButton
              variant="outlined"
              onClick={handleAuthToolsClick}>
              <ChecklistRtlIcon style={{ size: 'sm', marginRight: '8px' }} />
              Authorization Tools
            </CustomButton>
            {/*  <CustomButton
            variant="contained"
            onClick={handleDialogOpen}>
            <FaUpload style={{ marginRight: '8px' }} />
            Import 835 File
          </CustomButton>
          <CustomButton
            variant="contained"
            onClick={handleDownload837File}
          >
            <ToolsImg src={exportIcon} alt="" />
            Download 837
          </CustomButton>
          <CustomButton
            variant="contained"
            onClick={handleDownload837File}
          >
            <FaPaperPlane style={{ marginRight: '8px' }} />
            Transmit Claims
          </CustomButton> */}

            <CustomButton
              variant="outlined"
              onClick={handleRefreshData}
            >
              <SyncIcon style={{ marginRight: '8px' }} />
              Refresh
            </CustomButton>
          </TableControlsContainer>)}
        </Box>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
          <FilterChipList>
            {authorizationstatuses && authorizationstatus ?
              <FilterChip>
                <span>Status: </span>{authorizationstatuses[authorizationstatus - 1]?.name}
                <CrossImg src={crossSvg} alt="" onClick={onResetAuthorizationStatus} />
              </FilterChip> : null
            }
            {fromSource !== "client" && clients && client ? (
              <FilterChip>
                <span>Client: </span>{clients[client - 1]?.firstName} {clients[client - 1]?.lastName}
                <CrossImg src={crossSvg} alt="" onClick={onResetClient} />
              </FilterChip>
            ) : null}
            {services && service ?
              <FilterChip>
                <span>Service: </span>{services[service - 1]?.name}
                <CrossImg src={crossSvg} alt="" onClick={onResetService} />
              </FilterChip> : null
            }
            {levelofcares && levelofcare ?
              <FilterChip>
                <span>Level of Care: </span>{levelofcares[levelofcare - 1]?.name}
                <CrossImg src={crossSvg} alt="" onClick={onResetLevelofCare} />
              </FilterChip> : null
            }
            {authstatuses && authstatus ?
              <FilterChip>
                <span>Authorization: </span>{authstatuses[authstatus - 1]?.name}
                <CrossImg src={crossSvg} alt="" onClick={onResetAuthStatus} />
              </FilterChip> : null
            }
          </FilterChipList>

        </Box>
        <Box sx={{ height: 680, width: '100%' }}>
          <DataGridPro
            rows={rows}
            columns={dynamicColumns}
            treeData={isTreeView}
            getTreeDataPath={(row) => {
              if (isTreeView) {
                const serviceDate = row.serviceDate ? row.serviceDate : "No Date";
                // Append the row ID to ensure uniqueness
                return [moment(serviceDate).format("dddd, M/D/YYYY"), row.id];
              }
              return [];
            }}
            groupingColDef={{
              headerName: "", // Custom header name
              width: 250, // Optional: customize the column width
            }}
            pageSize={rowsPerPage}
            rowsPerPageOptions={[25, 50, 100]}
            pagination
            onPageChange={(params) => handleChangePage(params.page)}
            onPageSizeChange={handleChangeRowsPerPage}
            //sortModel={[{ field: orderBy, sort: order }]}
            /* onSortModelChange={(model) => {
              if (model.length) {
                setOrder(model[0].sort);
                setOrderBy(model[0].field);
              }
            }} */
            disableSelectionOnClick
            disableCellFocusOutline
            disableColumnSelector
            components={{
              LoadingOverlay: CustomLoadingOverlay, // Custom loading spinner overlay
              NoRowsOverlay: () => <EmptyMessage>No data to show</EmptyMessage>,
              Toolbar: CustomToolbar, // Custom toolbar
            }}
            sx={{
              fontSize: '0.85rem',
              color: '#424242',
              backgroundColor: 'grey.100', // Setting background color to white
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: 'white',
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold', // Making header text bold
              },
              '& .MuiDataGrid-row':
              {
                backgroundColor: 'white'
              },
              '& .MuiDataGrid-treeDataExpandIconCell': {
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
              },
              '& .MuiDataGrid-cell': {
                paddingLeft: '10px', // Ensure enough padding for expand/collapse buttons
              },
            }}
          />
        </Box>
        {/*  {selectedRecord && (
          <BillingEditModal
            open={isEditModalOpen}
            anchorEl={anchorEl}
            onClose={handleCloseEditModal}
            record={selectedRecord}
            onSave={handleSave}
          />
        )} */}

        {selectedRecord && billingHistory && billingHistory.length > 0 && (
          <AuthStatusHistoryStepper
            isHistoryDialogOpen={isHistoryDialogOpen}
            handleCloseHistoryDialog={handleCloseHistoryDialog}
            isLoading={isGetBillingHistoryLoading}
            error={null}
            billingHistory={billingHistory}
          />
        )}

        {/*       {fromSource === "authorization" && (<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
        <ResultData>
          <span>Total rate: </span>
          {new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(data?.totalRate)}
        </ResultData>
      </div>)} */}
      </StyledPaper>

      <Popover
        id={isFilterPopoverOpen ? 'filter-popover' : undefined}
        open={isFilterPopoverOpen}
        anchorEl={filterAnchorEl}
        onClose={handleFilterDialogClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2, minWidth: 200, maxWidth: 1000 }}>
        </Box>
      </Popover>


      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        maxWidth="sm" // Changed to 'sm' to make the dialog smaller
        fullWidth
        PaperProps={{
          sx: {
            width: '625px', // Set a smaller width for the dialog
            maxWidth: '80vw', // Ensure it’s responsive on smaller screens
            position: 'relative', // Allow for absolute positioning within the Dialog
          },
        }}
      >
        <DialogTitle>
          Upload 835 File
          <IconButton
            aria-label="close"
            onClick={handleDialogClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px', marginTop: '16px' }}>
            <UploadFileContainer>
              <FileUpload buttonValue={'Choose 835 file'} value={fileValue835} setValue={handleDownload835File} />
            </UploadFileContainer>
            <CustomButton
              variant="contained"
              onClick={null}
              style={{ marginTop: '5px', marginLeft: '60px' }}
              disabled={!fileValue835}
            >
              <FaUpload style={{ color: 'white', marginRight: '8px' }} />
              Upload 835
            </CustomButton>
          </Box>
        </DialogContent>

        {/* Container for ErrorAlert */}
        <Box
          sx={{
            position: 'absolute',
            bottom: 8, // Adjust the distance from the bottom as needed
            left: '50%', // Center horizontally
            transform: 'translateX(-45%)', // Center horizontally
            width: '90%', // Adjust width as needed
          }}
        >
          <ErrorAlert
            severity={message === 'File uploaded successfully' ? 'success' : 'error'}
            handleClose={handleClose}
            open={open}
            errorMessage={message}
          />
        </Box>

        <DialogActions>
          <CustomButton onClick={handleDialogClose} color="primary">
            Close
          </CustomButton>
        </DialogActions>
      </Dialog>


      {/* ClientAuthorizationForm Dialog */}
      {selectedRecord && (
        <Dialog
          open={isAuthorizationDialogOpen}
          onClose={handleAuthorizationDialogClose}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>
            {selectedRecord.id ? "Edit Authorization" : "New Authorization"}
            <IconButton
              aria-label="close"
              onClick={handleAuthorizationDialogClose}
              sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <ClientAuthorizationForm
              record={selectedRecord}
              onClose={handleAuthorizationDialogClose}
              onSuccess={() => {
                handleAuthorizationDialogClose();
                handleRefreshData();
              }}
            />
          </DialogContent>
        </Dialog>
      )}

      {/* Billing Tools Menu */}
      <Menu
        anchorEl={anchorEl}
        open={isBillingToolsOpen}
        onClose={handleBillingToolsClose}
        MenuListProps={{
          'aria-labelledby': 'settings-button',
        }}
      >
        <MenuItem onClick={() => handleConfirmDialogOpen("Send837")}>
          <ListItemIcon>
            <SendIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Transmit Claims</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleDialogOpen()}>
          <ListItemIcon>
            <BackupIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Import 835 file</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleConfirmDialogOpen("GetERA")}>
          <ListItemIcon>
            <PriceCheckIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Check For ERA Records</ListItemText>
        </MenuItem>
      </Menu>

      {/* Authorization Tools Menu */}
      <Menu
        anchorEl={anchorEl}
        open={isAuthToolsOpen}
        onClose={handleAuthToolsClose}
        MenuListProps={{
          'aria-labelledby': 'settings-button',
        }}
      >
        <MenuItem onClick={() => handleDownload278File()}>
          <ListItemIcon>
            <CloudDownloadIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Download 278 Request File</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleDialogOpen()}>
          <ListItemIcon>
            <BackupIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Import 278 Response File</ListItemText>
        </MenuItem>
      </Menu>

      {/* Confirm Dialog */}
      <ConfirmProcessingDialog
        titleText={titleText}
        contentText={contentText}
        open={isConfirmDialogOpen}
        setOpen={setIsConfirmDialogOpen}
        onResponse={handleDialogResponse}
        handleAction={dialogAction} // Pass the dynamic action
        message={dialogMessage}
      />


    </Box>


  );


}