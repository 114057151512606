import React, { useState, useEffect } from "react";
import { Box, Grid, IconButton, Tooltip, Skeleton } from "@mui/material";
import InputField from "../../../common/InputField";
import DatePickerField from "../../../common/DatePickerField";
import SelectField from "../../../common/SelectFieldForForm";
import CheckboxField from "../../../common/CheckboxField";
import {
  FlexContainer,
  GridContainer,
  Groups,
  Img,
  ImgContainer,
} from "./BasicInfo.styled";
import {
  useGetClientStatusesQuery,
  useGetCompanyBranchesQuery,
  useGetEthnicitiesQuery,
  useGetGenderIdentitiesQuery,
  useGetGendersQuery,
  useGetMaritalStatusesQuery,
  useGetRequestedServicesQuery,
  useGetSexualOrientationsQuery,
  useGetTracksQuery,
  useGetClientHousingsQuery,
} from "../../../../services/UtilityService";
import SelectWithCheckbox from "../../../common/SelectWithCheckbox";
import { Field, FastField, useField, useFormikContext } from "formik";
import { useParams } from "react-router-dom";
import { useGetLevelOfCareQuery, useGetProfilePictureQuery } from "../../../../services/ClientService";
import useFetchWithMsal from "../../../../utils/useFetchWithMsal";
import EditIcon from "@mui/icons-material/EditTwoTone";

export const BasicInfo = ({ fields }) => {
  const { id } = useParams();
  const { skip } = useFetchWithMsal();

  const { data: status } = useGetClientStatusesQuery("", { skip });
  const { data: companyBranch } = useGetCompanyBranchesQuery("", { skip });
  const { data: tracks } = useGetTracksQuery("", { skip });
  const { data: genders } = useGetGendersQuery("", { skip });
  const { data: sexualOrientations } = useGetSexualOrientationsQuery("", {
    skip,
  });
  const { data: genderIdentities } = useGetGenderIdentitiesQuery("", { skip });
  const { data: ethnicities } = useGetEthnicitiesQuery("", { skip });
  const { data: maritals } = useGetMaritalStatusesQuery("", { skip });
  const { data: requestedServices } = useGetRequestedServicesQuery("", {
    skip,
  });

  const { data: profilePicture, error, isLoading } = useGetProfilePictureQuery(id, { skip });
  const { data: levelOfCareList } = useGetLevelOfCareQuery({ skip });
  const { data: houses } = useGetClientHousingsQuery({ skip });

  const [field] = useField({ name: "profilePicture" });
  const { value } = field;
  const { setFieldValue } = useFormikContext();
  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64String = e.target.result;
        setFieldValue("profilePicture", {
          base64Image: base64String.split(",")[1],
          mimeType: file.type,
        });
      };

      reader.readAsDataURL(file);
    }
  };

  const getSelectData = (name) => {
    switch (name) {
      case "statusId":
        return status;
      case "companyBranchId":
        return companyBranch;
      case "track":
        return tracks;
      case "genderId":
        return genders;
      case "sexualOrientationId":
        return sexualOrientations;
      case "genderIdentityId":
        return genderIdentities;
      case "primaryEthnicityId":
        return ethnicities;
      case "secondaryEthnicityId":
        return ethnicities;
      case "maritalStatusId":
        return maritals;
      case "requestedService":
        return requestedServices;
      case "levelOfCareId":
        return levelOfCareList;
      case "clientHousingId":
        return houses;
      default:
        return [];
    }
  };

  const findGenderId = (values, field) => {
    if (values.hasOwnProperty(field)) {
      return values[field];
    }
  };

  const getInputComponent = (item, width, height) => {
    switch (item.type) {
      case "select":
        return (
          <Field name={item.name}>
            {({ field, form }) => (
              <SelectField
                name={item.name}
                label={item.label}
                data={getSelectData(item.name)}
                value={field.value}
                setValue={form.setFieldValue}
                error={form.errors[item.name]}
                fullWidth
              />
            )}
          </Field>
        );
      case "selectCheckbox":
        return (
          <Field name={item.name}>
            {({ field, form }) => (
              <SelectWithCheckbox
                name={item.name}
                label={item.label}
                data={getSelectData(item.name)}
                value={field.value}
                setValue={form.setFieldValue}
                error={form.errors[item.name]}
                fullWidth
              />
            )}
          </Field>
        );
      case "date":
        return (
          <FastField name={item.name}>
            {({ field, form }) => (
              <DatePickerField
                name={item.name}
                label={item.label}
                value={field.value}
                setValue={form.setFieldValue}
                error={form.errors[item.name]}
                maxDate={new Date()}
              />
            )}
          </FastField>
        );
      case "checkbox":
        return (
          <FastField name={item.name}>
            {({ field, form }) => (
              <CheckboxField
                name={item.name}
                label={item.label}
                value={field.value}
                setValue={form.setFieldValue}
                error={form.errors[item.name]}
              />
            )}
          </FastField>
        );
      case "img":
        return;
      case "otherInput":
        return (
          <FastField name={item.name}>
            {({ field, form }) => (
              <InputField
                name={item.name}
                label={item.label}
                value={
                  findGenderId(form.values, item.pairField) ===
                    item.pairFieldOtherId
                    ? field.value
                    : ""
                }
                setValue={form.setFieldValue}
                error={form.errors[item.name]}
                fullWidth
                width={width}
                height={height}
                disabled={
                  findGenderId(form.values, item.pairField) !==
                  item.pairFieldOtherId
                }
              />
            )}
          </FastField>
        );
      default:
        return (
          <FastField name={item.name}>
            {({ field, form }) => (
              <InputField
                name={item.name}
                label={item.label}
                value={field.value || ""}
                setValue={form.setFieldValue}
                error={form.errors[item.name]}
                fullWidth
                width={width}
                height={height}
              />
            )}
          </FastField>
        );
    }
  };
  return (
    <React.Fragment>
      <Grid container rowSpacing={"20px"} columnSpacing={"20px"}>
        <Grid item xs={4}>
          <Box>
            <Box mb={"20px"}>{getInputComponent(fields.company)}</Box>
            {getInputComponent(fields.companyBranchId)}
          </Box>
        </Grid>

        <Grid item xs={4}>
          <GridContainer gap={"20px"} template={"207px 60px"} mb={"20px"}>
            <Box>{getInputComponent(fields.firstName, "207px")}</Box>
            <div>{getInputComponent(fields.middleName, "60px")}</div>
          </GridContainer>
          <GridContainer gap={"20px"} template={"207px 60px"}>
            <div>{getInputComponent(fields.lastName, "207px")}</div>
            <div>{getInputComponent(fields.suffixName, "60px")}</div>
          </GridContainer>
        </Grid>
        <Grid item xs={3}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                position: "relative", // Make the image container the reference point
                width: "150px", // Ensure explicit width
                height: "150px", // Ensure explicit height
                borderRadius: "4px", // Optional: For rounded corners
                overflow: "hidden", // Clip content for a clean look

              }}
            >
              {/* Show Skeleton while loading or until the image is loaded */}
              {isLoading && (
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height="100%"
                  sx={{ borderRadius: "4px" }}
                />
              )}

              {profilePicture && (<img
                alt="clientPhoto"
                src={
                  value
                    ? `data:image/jpeg;base64,${value.base64Image}`
                    : profilePicture
                }
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover", // Ensures the image fits perfectly
                }}
              />)}
              {!isLoading && (
                <IconButton
                  component="label"
                  sx={{
                    position: "absolute", // Place it inside the container
                    width: "40px",
                    height: "40px",
                    bottom: "2px", // 10px from the bottom edge
                    right: "2px", // 10px from the right edge
                    backgroundColor: "rgba(255, 255, 255, 0.1)", // White with 50% transparency
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
                    "&:hover": {
                      backgroundColor: "rgba(200, 200, 200, 0.5)", // Slightly darker on hover with transparency
                    },
                  }}
                >
                  <Tooltip title="Change Picture" arrow>
                    <EditIcon sx={{ marginTop: "4px" }} />
                    <input
                      type="file"
                      hidden
                      onChange={handleImageUpload}
                      name={"profilePicture"}
                    />
                  </Tooltip>
                </IconButton>
              )}
            </Box>
          </Box>
        </Grid>
        {/* <Grid item xs={4}>
          {getInputComponent(fields.CheckIfDecead)}
        </Grid> */}
        <Grid item xs={12}>
          <Box
            sx={{
              borderBottom: "1px solid",
              borderColor: "primary.main",
              margin: "10px 0",
            }}
          ></Box>
        </Grid>
        <Grid item xs={4}>
          {getInputComponent(fields.preferredName)}
        </Grid>
        <Grid item xs={4}>
          {getInputComponent(fields.maritalStatusId)}
        </Grid>
        <Grid item xs={4}>
          {getInputComponent(fields.primaryEthnicity)}
        </Grid>
        <Grid item xs={4}>
          {getInputComponent(fields.previousName)}
        </Grid>
        <Grid item xs={4}>
          {getInputComponent(fields.birthDate)}
        </Grid>
        <Grid item xs={4}>
          {getInputComponent(fields.secondaryEthnicity)}
        </Grid>
        <Grid item xs={4}>
          {getInputComponent(fields.maidenName)}
        </Grid>
        <Grid item xs={4}>
          {getInputComponent(fields.gangAffiliation)}
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              borderBottom: "1px solid",
              borderColor: "primary.main",
              margin: "10px 0",
            }}
          ></Box>
        </Grid>
        <Grid item xs={4}>
          <Groups>
            {getInputComponent(fields.genderId)}

            <Box> {getInputComponent(fields.genderDescription)}</Box>
          </Groups>
        </Grid>
        <Grid item xs={4}>
          <Groups>
            {getInputComponent(fields.sexualOrientationId)}
            <Box>{getInputComponent(fields.sexualOrientationDescription)}</Box>
          </Groups>
        </Grid>
        <Grid item xs={4}>
          <Groups>
            {getInputComponent(fields.genderIdentityId)}
            <Box>{getInputComponent(fields.genderIdentityDescription)}</Box>
          </Groups>
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              borderBottom: "1px solid",
              borderColor: "primary.main",
              margin: "10px 0",
            }}
          ></Box>
        </Grid>

        <Grid item xs={4}>
          {getInputComponent(fields.ssn)}
        </Grid>
        <Grid item xs={4}>
          {getInputComponent(fields.driversLicense)}
        </Grid>
        {/*         <Grid item xs={4}>
          {getInputComponent(fields.lounds)}
        </Grid> */}
        {/*  <Grid item xs={4}>
          {getInputComponent(fields.taxId)}
        </Grid> */}

        <Grid item xs={12}>
          <Box
            sx={{
              borderBottom: "1px solid",
              borderColor: "primary.main",
              margin: "10px 0",
            }}
          ></Box>
        </Grid>

        <Grid item xs={4}>
          <Grid gap={"20px"} container>
            <Box width={"100%"}>{getInputComponent(fields.statusId)}</Box>
            <Box width={"100%"}>{getInputComponent(fields.track)}</Box>
            <Box width={"100%"}>
              {getInputComponent(fields.requestedService)}
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid gap={"20px"} container>
            <Box width={"100%"}>{getInputComponent(fields.sbi)}</Box>
            <Box width={"100%"}>{getInputComponent(fields.account)}</Box>
            <Box width={"100%"}>{getInputComponent(fields.clientID)}</Box>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid gap={"20px"} container>
            <Box width={"100%"}>{getInputComponent(fields.levelOfCare)}</Box>
            <Box width={"100%"}>{getInputComponent(fields.clientHousingId)}</Box>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
