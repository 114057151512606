import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { logDOM } from "@testing-library/react";
import moment from "moment";
import { saveAs } from 'file-saver';

const getTokenFromLocalStorage = () => {
  const token = localStorage.getItem("token");
  return token;
};

export const clientAPI = createApi({
  reducerPath: "clientAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://emr-dev-web-api.azurewebsites.net",
    //baseUrl: "https://localhost:7202",
    prepareHeaders: (headers, { getState }) => {
      const token = getTokenFromLocalStorage();
      if (token) {
        // include token in req header
        headers.set("authorization", `Bearer ${token}`);
        return headers;
      }
    },
  }),
  tagTypes: ["Client", "Clients", 'Service'],
  endpoints: (build) => ({
    fetchClient: build.query({
      query: (id) => ({
        url: `api/Client/${id}`,
      }),
      providesTags: (result) => ["Client"],
      transformErrorResponse: (response) => {
        return { message: response.data.Message || response.data?.title };
      },
    }),
    postClient: build.mutation({
      query: (client) => ({
        url: `api/Client/SaveClient`,
        method: "POST",
        body: client,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message || response.data?.title };
      },
    }),
    getUserInfo: build.query({
      query: () => ({
        url: `/api/Users/Me`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getClients: build.mutation({
      query: (body) => {
        // Log the body to the console
        console.log("Request Body:", {
          recordNumber: body.recordNumber,
          offset: body.offset,
          status: body.status || 0,
          counselor: body.counselor || null,
          levelofcare: body.levelofcare || 0,
          housing: body.housing || 0,
          searchValue: body.searchVal,
          orderBy: body.orderBy,
          orderByDirection: body.order,
        });

        return {
          url: `/api/Client/GetClientsByFilter`,
          method: "POST",
          body: {
            recordNumber: body.recordNumber,
            offset: body.offset,
            status: body.status || 0,
            counselor: body.counselor || null,
            levelofcare: body.levelofcare || 0,
            housing: body.housing || 0,
            searchValue: body.searchVal,
            orderBy: body.orderBy,
            orderByDirection: body.order,
          },
        };
      },
      async onQueryStarted(body, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          console.log("API Response:", data); // Log the API response here
        } catch (error) {
          console.error("API Error:", error);
        }
      },
    }),
    getBillingReport: build.mutation({
      query: (body) => {
        // Log to show billingStatus filter being passed
        console.log("getBillingReport filters:", body);

        return {
          url: `/api/ClientService/GetBillingReport`,
          method: "POST",
          body: {
            recordNumber: body.recordNumber,
            offset: body.offset,
            clientIdList: body.clientIdList || null,
            orderBy: body.orderBy,
            orderByDirection: body.order,
            serviceDate: body.serviceDate || null,
            endDate: body.endDate || null,
            serviceIdList: body.serviceIdList || null,
            levelOfCareIdList: body.levelOfCare || null,
            billingStatusIdList: body.billingStatus || null,
            serviceStatusIdList: body.serviceStatus || null,
            authorizationList: body.authorizationList || null,
            ownerList: body.serviceOwner || null,
          },
        };
      },
      transformErrorResponse: (response) => {
        return { message: response.data?.Message };
      },
    }),
    postUpload835File: build.mutation({
      query: (body) => ({
        url: `/api/ClientService/Upload/835File`,
        method: "POST",
        body: body,
      }),
      transformResponse: (response) => {
        // Directly extract and return the message from the JSON response
        console.log("API Response (transformResponse):", response); // Debugging line
        return { message: response.message || 'File uploaded successfully' };
      },
      transformErrorResponse: (response) => {
        // Handle error response by returning the message or default error message
        console.log("API Error Response:", response); // Debugging line
        return { message: response.data?.Message || 'An error occurred' };
      },
    }),
    downloadFile837: build.mutation({
      queryFn: async (body) => {
        try {
          // Log the filters
          console.log('Download 837 File Filters:', {
            recordNumber: body.recordNumber,
            offset: body.offset,
            clientIdList: body.clientIdList || null,
            orderBy: body.orderBy,
            orderByDirection: body.order,
            serviceDate: body.serviceDate || null,
            endDate: body.endDate || null,
            serviceIdList: body.serviceIdList || null,
            levelOfCareIdList: body.levelOfCare || null,
            billingStatusIdList: body.billingStatus || null,
          });

          const response = await fetch(
            `https://emr-dev-web-api.azurewebsites.net/api/ClientService/Download/837File`,
            {
              cache: "no-store",
              headers: {
                authorization: `Bearer ${getTokenFromLocalStorage()}`,
                "Content-Type": "application/json",
              },
              method: "POST",
              body: JSON.stringify({
                recordNumber: body.recordNumber,
                offset: body.offset,
                clientIdList: body.clientIdList || null,
                orderBy: body.orderBy,
                orderByDirection: body.order,
                serviceDate: body.serviceDate || null,
                endDate: body.endDate || null,
                serviceIdList: body.serviceIdList || null,
                levelOfCareIdList: body.levelOfCare || null,
                billingStatusIdList: body.billingStatus || null,
              }),
            }
          );

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const blob = await response.blob();
          saveAs(blob, `837(${moment(new Date()).utc().format('MM/DD/YYYY')}).csv`);

          return { data: 'File downloaded successfully' };
        } catch (e) {
          return { error: e.message };
        }
      },
    }),

    sendFile837: build.mutation({
      query: (body) => {
        console.log("sendFile837 filters:", body);

        return {
          url: `/api/ClientService/SendClaimData/837File`,
          method: "POST",
          body: {
            recordNumber: body.recordNumber,
            offset: body.offset,
            clientIdList: body.clientIdList || null,
            orderBy: body.orderBy,
            orderByDirection: body.order,
            serviceDate: body.serviceDate || null,
            endDate: body.endDate || null,
            serviceIdList: body.serviceIdList || null,
            levelOfCareIdList: body.levelOfCare || null,
            billingStatusIdList: body.billingStatus || null,
            authorizationList: body.authorizationList || null,
          },
        };
      },
      // Transform successful response
      transformResponse: (response) => {
        console.log("success response:", response);
        return { message: response.message || "File processed successfully!" };
      },
      // Transform error response
      transformErrorResponse: (response) => {
        console.log("error response:", response);
        return { message: response.error || "An error occurred while processing the file." };
      },
    }),

    getERAFiles: build.mutation({
      query: (body) => {

        return {
          url: `/api/ClientService/GetERAList`,
          method: "POST",
          body: {},
        };
      },
      // Transform successful response
      transformResponse: (response) => {
        console.log("success response:", response);
        return { message: response.message || "File processed successfully!" };
      },
      // Transform error response
      transformErrorResponse: (response) => {
        console.log("error response:", response);
        return { message: response.error || "An error occurred while processing the file." };
      },
    }),

    getProfilePicture: build.query({
      queryFn: async (id) => {
        try {
          const response = await fetch(
            `https://emr-dev-web-api.azurewebsites.net/api/Client/${id}/ProfilePicture`,
            //`https://localhost:7202/api/Client/${id}/ProfilePicture`,
            {
              cache: "no-store",
              headers: {
                authorization: `Bearer ${getTokenFromLocalStorage()}`,
              },
            }
          );
          const dataURL = await response.blob();
          return { data: await URL.createObjectURL(dataURL) };
        } catch (e) {
          return { error: e.message };
        }
      },
    }),

    getSearchPossibleValues: build.query({
      query: (data) => ({
        url: `/api/PsychosocialAssessmentForm/PossibleValues/${data.id}?searchQuery=${data.value}`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getPossibleValueById: build.query({
      query: (data) => {
        console.log(`getPossibleValueById called with id: ${data.id}, value: ${data.value}`);
        return {
          url: `/api/PsychosocialAssessmentForm/PossibleValue/${data.id}/${data.value}`,
        };
      },
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getAssessmentForm: build.query({
      query: (formId) => ({
        url: `api/PsychosocialAssessmentForm/${formId}`,
      }),
      transformResponse: (response) => {
        const getValue = (type) => {
          switch (type) {
            case 1:
            case 7:
              return "";
            case 2:
              return null;
            case 6:
              return [];
            default:
              return 0;
          }
        };
        const extractFields = (obj, result, validation) => {
          if (obj.formFields) {
            obj.formFields.forEach((field) => {
              result[field.id] = getValue(field.type);
              validation[field.id] = {
                validationType: field.required ? "required" : null,
                name: field.id,
              };
            });
          }
          if (obj.groups) {
            obj.groups.forEach((group) => {
              group.formFields.forEach((field) => {
                extractFields(group, result, validation);
              });
            });
          }
        };
        const fields = {};
        const validation = {};

        response.group.forEach((item) => {
          extractFields(item, fields, validation);
        });
        const sections = response.group
          ?.slice()
          .sort((a, b) => a.priority - b.priority);

        const requiredSignatures = response.requiredSignatures;

        const formTitle = response.name;

        return { sections, fields, validation, requiredSignatures, formTitle };
      },
      transformErrorResponse: (response) => {
        return { message: response.data.Message || response.data?.title };
      },
    }),
    getAssessmentForms: build.query({
      query: () => ({
        url: `api/PsychosocialAssessmentForm`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getAssessmentFormSignatures: build.query({
      query: ({ clientId, formId, clientServiceId }) => ({
        url: `api/PsychosocialAssessmentForm/${formId}/signatures?clientId=${clientId}&clientServiceId=${clientServiceId}`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message || response.data?.title };
      },
    }),
    signForm: build.mutation({
      query: (data) => {
        return {
          url: `/api/PsychosocialAssessmentForm/sign/${data.signatureId}?clientId=${data.userId}&clientServiceId=${data.clientServiceId}`,
          method: "PUT",
        };
      },
      transformErrorResponse: (response) => {
        return { message: response.data.Message || response.data?.title };
      },
    }),
    unsignForm: build.mutation({
      query: (data) => {
        return {
          url: `/api/PsychosocialAssessmentForm/sign/${data.signatureId}?clientId=${data.userId}&clientServiceId=${data.clientServiceId}`,
          method: "DELETE",
        };
      },
      transformErrorResponse: (response) => {
        return { message: response.data.Message || response.data?.title };
      },
    }),
    getAssessmentValues: build.query({
      queryFn: async ({ clientId, formId, clientServiceId }) => {
        try {
          // Log clientId, formId, and clientServiceId
          console.log(`Fetching assessment values for clientId: ${clientId}, formId: ${formId}, clientServiceId: ${clientServiceId}`);

          const response = await fetch(
            `https://emr-dev-web-api.azurewebsites.net/api/PsychosocialAssessmentForm/GetFieldValues/${clientId}?assessmentId=${formId}&clientServiceId=${clientServiceId}`,
            {
              headers: {
                authorization: `Bearer ${getTokenFromLocalStorage()}`,
              },
            }
          );

          const data = await response.json();
          return { data: await data };
        } catch (e) {
          return { message: e.message };
        }
      },
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getClientFormList: build.query({
      queryFn: async (clientId) => {
        try {
          const response = await fetch(
            `https://emr-dev-web-api.azurewebsites.net/api/PsychosocialAssessmentForm/ClientFormList/${clientId}`,
            {
              headers: {
                authorization: `Bearer ${getTokenFromLocalStorage()}`,
              },
            }
          );
          const data = await response.json();
          return { data: await data };
        } catch (e) {
          return { message: e.message };
        }
      },
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getUsersRoles: build.query({
      query: () => ({
        url: `/api/Users/Roles`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getNotesTypes: build.query({
      query: () => ({
        url: `/api/QuickNote/Types`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getRequestSatatus: build.query({
      query: () => ({
        url: `/api/Utility/QuickNote/dropdown/RequestSatatus`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getNotes: build.mutation({
      query: (data) => {
        return {
          url: `/api/QuickNote/paginated`,
          method: "PUT",
          body: data,
        };
      },
      transformErrorResponse: (response) => {
        return { message: response.data.Message || response.data?.title };
      },
    }),
    getNotesForPeriod: build.mutation({
      query: (data) => {
        return {
          url: `/api/QuickNote/OverTimePeriod`,
          method: "PUT",
          body: data,
        };
      },
      transformErrorResponse: (response) => {
        return { message: response.data.Message || response.data?.title };
      },
    }),
    postNote: build.mutation({
      query: (data) => ({
        url: `/api/QuickNote`,
        method: "POST",
        body: data,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message || response.data?.title };
      },
    }),
    editNote: build.mutation({
      query: ({ data, noteId }) => ({
        url: `/api/QuickNote/${noteId}`,
        method: "PATCH",
        body: data,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message || response.data?.title };
      },
    }),
    deleteNote: build.mutation({
      query: ({ data, noteId }) => ({
        url: `/api/QuickNote/${noteId}`,
        method: "DELETE",
        body: data,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message || response.data?.title };
      },
    }),

    getClientAssignmentHistory: build.query({
      query: (data) => (data.clientId && {
        url: `/api/ClientAssignment/History/${data.clientId}?roleId=${data.roleId}`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getUsers: build.query({
      query: (roleId) => ({
        url: `/api/Users/All?roleId=${roleId}`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    postAssessmentValues: build.mutation({
      query: (data) => ({
        url: `/api/PsychosocialAssessmentForm/SaveFieldValues/${data.clientId}?assessmentId=${data.formId}&clientServiceId=${data.clientServiceId}`,
        method: "POST",
        body: data.value,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message || response.data?.title };
      },
    }),
    putUserValue: build.mutation({
      query: (data) => {
        // Log the body data before sending the request
        console.log('PUT request body:', data);

        return {
          url: `/api/Users/Me`,
          method: "PUT",
          body: data,
        };
      },
      transformErrorResponse: (response) => {
        return { message: response.data.Message || response.data?.title };
      },
    }),
    putUserRoleValue: build.mutation({
      query: (data) => {
        // Log the body data before sending the request
        console.log('PUT role request body:', data);

        return {
          url: `/api/Users/UpdateUserRole`,
          method: "PUT",
          body: data,
        };
      },
      transformErrorResponse: (response) => {
        return { message: response.data.Message || response.data?.title };
      },
    }),
    putBillingStatus: build.mutation({
      query: (data) => {
        console.log("status data", data.newRecord.serviceId);
        return {
          url: `api/ClientService/Update/BillingStatus`,
          method: "POST",
          body: {
            id: 0, // Increment ID (use a better ID mechanism if available)
            serviceId: data.newRecord.serviceId,
            statusId: data.newRecord.statusId,
            note: "",
          },
        };
      },
      transformErrorResponse: (response) => {
        return { message: response.data.Message || response.data?.title };
      },
    }),
    getClientService: build.query({
      query: (clientId) => ({
        url: `/api/ClientService/${clientId}`,
      }),
      providesTags: (result) => ["Service"],
      transformErrorResponse: (response) => {
        return { message: response.data?.Message };
      },
    }),
    getClientServiceByServiceId: build.query({
      query: (serviceId) => ({
        url: `/api/ClientService/GetClientService/${serviceId}`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data?.Message };
      },
    }),
    getClientServiceStatistic: build.query({
      query: (clientId) => ({
        url: `/api/ClientService/GetClientServiceStatistic/${clientId}`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getAssignmentClients: build.mutation({
      query: (data) => {
        console.log('Request body sent:', data);
        return {
          url: `api/ClientAssignment/GetAssignmentClients`,
          method: "POST",
          body: data,
        };
      },
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getServices: build.query({
      query: () => ({
        url: `api/Utility/ClientService/dropdown/Service`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getEvents: build.mutation({
      query: (data) => ({
        url: `api/EventCalendar/GetEvents`,
        method: "POST",
        body: data
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getClientMedicationMethod: build.query({
      query: () => ({
        url: `api/ClientMedication/dropdown/Method`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getClientMedicationFrequency: build.query({
      query: () => ({
        url: `api/ClientMedication/dropdown/Frequency`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getClientMedicationDoseType: build.query({
      query: () => ({
        url: `api/ClientMedication/dropdown/DoseType`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getClientMedicationPrescriber: build.query({
      query: () => ({
        url: `api/ClientMedication/dropdown/Prescriber`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    createClientService: build.mutation({
      query: (data) => ({
        url: `/api/ClientService/SaveClientService`,
        method: "POST",
        body: data.data,
      }),
      async onQueryStarted(data, { dispatch, queryFulfilled }) {

        console.log("Request Data:", data); // Log the data being sent to the mutation
        try {
          const { data: id } = await queryFulfilled

          const newData = data.data
          // newData.id = id
          dispatch(
            clientAPI.util.updateQueryData("getClientService", data.clientId, (draft) => {
              newData.startDate = new Date(newData.startDate).toISOString().slice(0, -1)
              newData.endDate = new Date(newData.endDate).toISOString().slice(0, -1)
              draft.push(newData)
            })
          );
        } catch (e) {
          console.error(e);
        }
      },
      transformErrorResponse: (response) => {
        return { message: response.data.Message || response.data?.title };
      },
    }),
    updateClientService: build.mutation({
      query: (data) => ({
        url: `/api/ClientService/SaveClientService`,
        method: "POST",
        body: data,
      }),
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        try {
          dispatch(
            clientAPI.util.updateQueryData("getClientService", data.clientId, (draft) => {
              const service = draft.find((item) => item.id === data.id)
              service.description = data.description
              service.serviceId = data.serviceId
              service.name = data.name
              service.startDate = new Date(data.startDate).toISOString().slice(0, -1)
              service.endDate = new Date(data.endDate).toISOString().slice(0, -1)
              service.materials = data.materials
            })
          );
        } catch (e) {
          console.error(e);
        }
      },
      transformErrorResponse: (response) => {
        return { message: response.data.Message || response.data?.title };
      },
    }),
    getGroupName: build.query({
      query: () => ({
        url: `api/clientService/dropdown/groupName`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getCanceledBillingStatus: build.query({
      query: () => ({
        // url: `api/ClientService/dropdown/CanceledBillingStatus`,
        url: `api/ClientService/dropdown/BillingStatus`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getGroupName: build.query({
      query: () => ({
        url: `api/clientService/dropdown/groupName`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getTopic: build.query({
      query: () => ({
        url: `api/clientService/dropdown/topic`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getUrineScreenTestResult: build.query({
      query: () => ({
        url: `api/Utility/PsychosocialAssessmentForm/dropdown/UrineScreenTestResult`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getUrineScreenSubstance: build.query({
      query: () => ({
        url: `api/Utility/PsychosocialAssessmentForm/dropdown/UrineScreenSubstance`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    editUrineScreenSubstance: build.mutation({
      query: (data) => ({
        url: `api/Utility/PsychosocialAssessmentForm/dropdown/EditUrineScreenSubstance`,
        method: "POST",
        body: data,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message || response.data?.title };
      },
    }),
    removeUrineScreenSubstance: build.mutation({
      query: (substanceId) => ({
        url: `api/Utility/PsychosocialAssessmentForm/dropdown/RemoveUrineScreenSubstance/${substanceId}`,
        method: "DELETE",
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message || response.data?.title };
      },
    }),
    deleteUrineScreenFile: build.mutation({
      query: (fileId) => ({
        url: `api/PsychosocialAssessmentForm/FormFile/${fileId}`,
        method: "DELETE",
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message || response.data?.title };
      },
    }),
    getRoom: build.query({
      query: () => ({
        url: `api/clientService/dropdown/room`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getDrugOfChoice: build.query({
      query: () => ({
        url: `api/Utility/PsychosocialAssessmentForm/dropdown/DrugOfChoice`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getAbusedSubstances: build.query({
      query: () => ({
        url: `api/Utility/PsychosocialAssessmentForm/dropdown/AbusedSubstances`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getListOfAllergies: build.query({
      query: () => ({
        url: `api/Utility/Allergy/dropdown/ListOfAllergies`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getAllergySeverity: build.query({
      query: () => ({
        url: `api/Utility/Allergy/dropdown/AllergySeverity`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getAllergyCodeSystems: build.query({
      query: () => ({
        url: `api/Utility/Allergy/dropdown/AllergyCodeSystems`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getLevelOfCare: build.query({
      query: () => ({
        url: `api/Utility/ClientBasicInformation/dropdown/LevelOfCare`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getAuthorizationList: build.query({
      query: () => ({
        url: `api/ClientService/dropdown/Authorization`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getBillingStatus: build.query({
      query: () => ({
        url: `/api/ClientService/dropdown/BillingStatus`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getServiceStatus: build.query({
      query: () => ({
        url: `/api/ClientService/dropdown/Status`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getMunicipality: build.query({
      query: () => ({
        url: `/api/Utility/Client/dropdown/Municipality`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getAppReminderPref: build.query({
      query: () => ({
        url: `/api/Utility/Client/dropdown/AppReminderPref`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }), getClientServiceBillingHistory: build.query({
      query: (data) => ({
        url: `/api/ClientService/GetClientServiceBillingHistory/${data.serviceId}`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getERA: build.query({
      query: (processedStatusId) => ({
        url: `/api/ClientService/GetERAs/${processedStatusId}`,
      }),
      transformErrorResponse: (response) => {
        // Log the full response for debugging
        console.log('Error response from getERA:', response);
        return { message: response.data?.Message || 'An error occurred' };
      },
    }),
    // Add this to your ClientService or relevant service file
    downloadFileERAOld: build.mutation({
      query: (ERAId) => ({
        url: `/api/ClientService/GetERAPDF/${ERAId}`,
        method: 'GET',
        responseType: 'blob',
      }),
      transformResponse: (response) => response, // Pass the response as is for blob data
      transformErrorResponse: (response) => {
        console.log('Error response from DownloadFileERA:', response);
        return { message: response.data?.Message || 'An error occurred' };
      },
    }),
    downloadFileERA: build.mutation({
      queryFn: async (body) => {
        const { ERAId } = body;
        try {
          const response = await fetch(
            `https://emr-dev-web-api.azurewebsites.net/api/ClientService/GetERAPDF/${ERAId}`,
            //`https://localhost:7202/api/ClientService/GetERAPDF/${ERAId}`,
            {
              cache: "no-store",
              headers: {
                authorization: `Bearer ${getTokenFromLocalStorage()}`,
              },
              method: "GET",
            }
          );

          console.log('ERAid:', ERAId);

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          // Retrieve the PDF blob data
          const blob = await response.blob();

          // Use `saveAs` from file-saver to initiate the download with a specific filename
          saveAs(blob, `ERA_${ERAId}_${moment().format('YYYY-MM-DD')}.pdf`);

          return { data: 'File downloaded successfully' };
        } catch (e) {
          return { error: e.message };
        }
      },
    }),
    saveClientAuthorization: build.mutation({
      query: (clientAuthorization) => {
        console.log("clientAuthorization object before API call:", clientAuthorization); // Log the object here
        return {
          url: `/api/ClientAuthorization/SaveClientAuthorization`,
          method: "POST",
          body: clientAuthorization,
        };
      },
      transformErrorResponse: (response) => {
        return {
          message: response.data?.Message || response.data?.title || 'An error occurred'
        };
      },
    }),
    getClientAuthorization: build.query({
      query: (authorizationId) => ({
        url: `/api/ClientAuthorization/${authorizationId}`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data?.Message };
      },
    }),
    getAuthorizationStatuses: build.query({
      query: () => ({
        url: `api/ClientAuthorization/dropdown/AuthorizationStatuses`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getAuthorizationDenialReasons: build.query({
      query: () => ({
        url: `api/ClientAuthorization/dropdown/AuthorizationDenials`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getAuthorizationReport: build.mutation({
      query: (body) => {
        try {
          // Log to show billingStatus filter being passed
          console.log("AuthorizationStatus filter:", body);

          return {
            url: `/api/ClientAuthorization/GetAuthorizationReport`,
            method: "POST",
            body: {
              recordNumber: body.recordNumber,
              offset: body.offset,
              clientIdList: body.clientIdList || null,
              orderBy: body.orderBy,
              orderByDirection: body.order,
              requestDate: body.requestDate || null,
              requestEndDate: body.requestEndDate || null,
              levelOfCareIdList: body.levelOfCare || null,
              authorizationStatusIdList: body.authorizationStatus || null,
              authorizationList: body.authorizationList || null,
            },
          };
        } catch (error) {
          console.log("Error building query:", error);
          throw new Error("Failed to build the request. Please check the input data.");
        }
      },
      transformErrorResponse: (response) => {
        // Log detailed error response
        console.error("Authorization Report response error:", response);

        // Handle specific error cases
        if (response.status === 400) {
          return { message: "Bad Request: Please verify the input data." };
        }
        if (response.status === 404) {
          return { message: "The requested resource was not found." };
        }
        if (response.status === 500) {
          return { message: "Server Error: Please try again later." };
        }

        // Default error message
        console.log("message", response);
        return { message: response.data?.Message || "An unknown error occurred." };
      },
    }),
    downloadFile278: build.mutation({
      queryFn: async (body) => {
        try {
          // Log the filters
          console.log('Download 278 File Filters:', {
            recordNumber: body.recordNumber,
            offset: body.offset,
            clientIdList: body.clientIdList || null,
            orderBy: body.orderBy,
            orderByDirection: body.order,
            serviceDate: body.serviceDate || null,
            endDate: body.endDate || null,
            serviceIdList: body.serviceIdList || null,
            levelOfCareIdList: body.levelOfCare || null,
            billingStatusIdList: body.billingStatus || null,
          });

          const response = await fetch(
            //`https://emr-dev-web-api.azurewebsites.net/api/ClientAuthorization/Download/278File`,
            `https://localhost:7202/api/ClientAuthorization/Download/278File`,
            {
              cache: "no-store",
              headers: {
                authorization: `Bearer ${getTokenFromLocalStorage()}`,
                "Content-Type": "application/json",
              },
              method: "POST",
              body: JSON.stringify({
                recordNumber: body.recordNumber,
                offset: body.offset,
                clientIdList: body.clientIdList || null,
                orderBy: body.orderBy,
                orderByDirection: body.order,
                serviceDate: body.serviceDate || null,
                endDate: body.endDate || null,
                serviceIdList: body.serviceIdList || null,
                levelOfCareIdList: body.levelOfCare || null,
                billingStatusIdList: body.billingStatus || null,
              }),
            }
          );

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const blob = await response.blob();
          saveAs(blob, `278(${moment(new Date()).utc().format('MM/DD/YYYY')}).csv`);

          return { data: 'File downloaded successfully' };
        } catch (e) {
          return { error: e.message };
        }
      },
    }),
  }),
});
export const {
  useFetchClientQuery,
  usePostClientMutation,
  useGetClientsMutation,
  useGetProfilePictureQuery,
  useGetAssessmentFormQuery,
  useGetAssessmentFormsQuery,
  useGetSearchPossibleValuesQuery,
  useGetAssessmentValuesQuery,
  useGetPossibleValueByIdQuery,
  usePostAssessmentValuesMutation,
  useGetClientFormListQuery,
  useGetUsersRolesQuery,
  useGetUsersQuery,
  useGetAssessmentFormSignaturesQuery,
  usePutUserValueMutation,
  usePutUserRoleValueMutation,
  useGetClientServiceQuery,
  useGetClientServiceByServiceIdQuery,
  useCreateClientServiceMutation,
  useUpdateClientServiceMutation,
  useGetClientServiceStatisticQuery,
  useSignFormMutation,
  useGetUserInfoQuery,
  useUnsignFormMutation,
  useGetClientAssignmentHistoryQuery,
  useGetNotesTypesQuery,
  usePostNoteMutation,
  useGetNotesMutation,
  useGetNotesForPeriodMutation,
  useEditNoteMutation,
  useDeleteNoteMutation,
  useGetServicesQuery,
  useGetEventsMutation,
  useGetAssignmentClientsMutation,
  useGetGroupNameQuery,
  useGetRoomQuery,
  useGetTopicQuery,
  useGetRequestSatatusQuery,
  useGetUrineScreenTestResultQuery,
  useGetUrineScreenSubstanceQuery,
  useEditUrineScreenSubstanceMutation,
  useRemoveUrineScreenSubstanceMutation,
  useDeleteUrineScreenFileMutation,
  useGetClientMedicationDoseTypeQuery,
  useGetClientMedicationFrequencyQuery,
  useGetClientMedicationMethodQuery,
  useGetClientMedicationPrescriberQuery,
  useGetDrugOfChoiceQuery,
  useGetAbusedSubstancesQuery,
  useGetAllergyCodeSystemsQuery,
  useGetAllergySeverityQuery,
  useGetListOfAllergiesQuery,
  useGetBillingReportMutation,
  useGetClientServiceBillingHistoryQuery,
  useGetLevelOfCareQuery,
  useGetAuthorizationListQuery,
  useGetBillingStatusQuery,
  useGetServiceStatusQuery,
  useDownloadFile837Mutation,
  useSendFile837Mutation,
  useGetERAFilesMutation,
  useGetMunicipalityQuery,
  useGetAppReminderPrefQuery,
  usePostUpload835FileMutation,
  useGetCanceledBillingStatusQuery,
  usePutBillingStatusMutation,
  useGetERAQuery,
  useDownloadFileERAMutation,
  useSaveClientAuthorizationMutation,
  useGetClientAuthorizationQuery,
  useGetAuthorizationStatusesQuery,
  useGetAuthorizationReportMutation,
  useGetAuthorizationDenialReasonsQuery,
  useDownloadFile278Mutation,
} = clientAPI;
