import React from 'react';
import { Button, Grid, Box, Typography } from '@mui/material';
import { useGetClientServicesQuery } from '../../services/UtilityService';
import InputField from '../common/InputField';
import InputFieldFormik from '../common/InputFieldFormik';
import SelectFieldFormik from '../common/SelectFieldFormik';
import CheckboxField from '../common/CheckboxField';
import list from "../../shared/icons/list.svg";
import history from "../../shared/icons/history.svg";
import bluePen from "../../shared/icons/bluePen.svg";
import blueTrash from "../../shared/icons/blueTrash.svg";
import roundedPlus from "../../shared/icons/roundedPlus.svg";
import {
    ButtonContainer,
    ButtonsContainer,
    ClientsTitleContainer,
    EmptyMessage,
    HeaderContainer,
    ResultData,
    TableControlsContainer,
    TableSearchContainer,
    ToolsImg,
    UploadFileContainer,
    StyledButton,
} from './ClientAuthorizationForm.styled';
import useFetchWithMsal from '../../utils/useFetchWithMsal';

const ClientAuthorizedServiceForm = ({ index, remove, values, setFieldValue  }) => {

    const { skip } = useFetchWithMsal();
    const { data: services } = useGetClientServicesQuery("", { skip });

    const handleDelete = () => {
        if (values?.id === 0) {
            // If the ID is 0, remove the record entirely
            remove(index);
        } else {
            // If the ID is greater than 0, mark it as deleted
            setFieldValue(`AuthorizedServices[${index}].isDeleted`, true);
        }
    };

    // Skip rendering if the item is marked as deleted
    if (values?.isDeleted) {
        return null;
    }

    return (
        <Box sx={{ padding: '1em', borderBottom: '1px solid #ccc', borderRadius: '4px', marginBottom: '1em' }}>
            <Grid container spacing={0.5} alignItems="center">
                <Grid item xs={2}>
                    <InputFieldFormik
                        name={`AuthorizedServices[${index}].procedureCode`}
                        label="Procedure Code"
                        type="text"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={1}>
                    <InputFieldFormik
                        name={`AuthorizedServices[${index}].modifier`}
                        label="Modifier"
                        type="text"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <SelectFieldFormik
                        name={`AuthorizedServices[${index}].serviceId`}
                        label="Service"
                        data={services}
                    />
                </Grid>
                <Grid item xs={2}>
                    <InputFieldFormik
                        name={`AuthorizedServices[${index}].rate`}
                        label="Rate"
                        type="number"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <InputFieldFormik
                        name={`AuthorizedServices[${index}].units`}
                        label="Units"
                        type="number"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={1} >
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 2.5, }}>
                    <Button
                            onClick={handleDelete}
                            color="secondary"
                            fullWidth
                            startIcon={<img src={blueTrash} alt="Delete" />}
                        >
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ClientAuthorizedServiceForm;
