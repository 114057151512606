import styled from "styled-components";

export const GridContainer = styled.div`
  display: grid;
  gap: ${(props) => (props.gap ? props.gap : "28px")};
  grid-template-columns: ${(props) =>
    props.template ? props.template : "inherit"};
  align-items: end;
  margin-bottom: ${(props) => (props.mb ? props.mb : "0")};
`;
export const ImgContainer = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  align-items: end;
  justify-content: center;
`;
export const FlexContainer = styled.div`
  width: ${(props) => (props.width ? props.width : "auto")};
  display: flex;
  justify-content: ${(props) => (props.position ? props.position : "inherit")};
  align-items: end;
  margin-bottom: ${(props) => (props.mb ? props.mb : 0)};
  margin-top: ${(props) => (props.mt ? props.mt : 0)};
  gap: 20px
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Groups = styled.div`
  max-width: 290px;
  background-color: rgba(239, 245, 248, 0.5);
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ElementFragment = styled.div`
  background-color: #fff;
  height: 300px;
  overflow-y: auto;
  margin-bottom: 0px;
`;

export const PopupBody = styled.div`
  padding: " 0px 0px 0px 0px",
  "&:focus-visible ": {
    outline: "none",
  },
`;

export const PopupFooter = styled.div`
  display: flex;
  padding: 20px;
  width: 100%;
  align-items: center;
  position: sticky;
  bottom: 0;
  background: #fff;
  z-index: 1;
`;

export const ElementHeader = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #C7DBE8;
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const ElementBody = styled.div`
  padding: 20px;
`;

export const Circle = styled.div`
  width: 9px;
  height: 9px;
  background-color: ${(props) => (props.active ? '#13ae5c' : "#9DB7C9")}; ;
  border-radius: 50%;
`;

export const AddNewButton = styled.div`
  display: flex;
  padding: 12px 5px;
  align-items: center;
  text-transform: uppercase;
  color: #4281AB;
  border: 1px solid #659DBD;
  cursor: pointer;
  border-radius: 2px;
  width: 103px;
  font-size: 12px;
  height: 26px;
  & img {
    margin-right: 6px;
  }
`;
