import React from "react";

import {
  ButtonWithoutStyle,
  StyledIcon,
  StyledLink,
  ToolsButtonGroup,
  ToolsContainer,
} from "./ClientInfoTools.styled";
import { Button, Typography } from "@mui/material";
import back from "../../../shared/icons/back.png";
import notes from "../../../shared/icons/notes.svg";
import { useNavigate } from 'react-router-dom';

export const ClientInfoTools = ({
  onCollapseClick,
  setNotesVisible,
  notesVisible,
  onExpandAll,
  name,
  id,
  isDirty,
  clientName,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (name === 'Client') {
      navigate('/home');
    } else {
      navigate(`/client/${id}`);

    }
    //window.location.reload();

  };

  return (
    <ToolsContainer>
      <span onClick={handleClick} style={{ cursor: 'pointer', marginLeft: '8px' }}>
        <img src={back} alt="" />
      </span>
      <Typography variant="h2" style={{ flex: 1 }}>{clientName}</Typography>
      <ToolsButtonGroup>
        <ButtonWithoutStyle onClick={onExpandAll} type="button">
          <Typography variant="subtitle1">Expand all</Typography>
        </ButtonWithoutStyle>
        <Typography variant="subtitle1"> &#8226; </Typography>
        <ButtonWithoutStyle onClick={onCollapseClick} type="button">
          <Typography variant="subtitle1"> Collapse all</Typography>
        </ButtonWithoutStyle>
      </ToolsButtonGroup>
      <ToolsButtonGroup style={{ marginRight: '8px' }}>
        <Button variant="outlined" size="small" type="reset" onClick={() => setNotesVisible(!notesVisible)}>
          <img src={notes} style={{ marginRight: '9px' }} />
          Notes & Alerts
        </Button>
        <Button variant="outlined" size="small" type="reset">
          Discard
        </Button>
        <Button variant="contained" size="small" type="submit">
          Save
        </Button>
      </ToolsButtonGroup>
    </ToolsContainer>
  );
};
