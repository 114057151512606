import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Stepper,
    Step,
    StepLabel,
    StepContent,
    CircularProgress,
    Typography,
    Box,
} from "@mui/material";
import moment from "moment";
import {
    HourglassEmpty as HourglassEmptyIcon,
    Delete as DeleteIcon,
    Block as BlockIcon,
    Cancel as CancelIcon,
    Redo as RedoIcon,
    AttachMoney as AttachMoneyIcon,
    CheckCircle as CheckCircleIcon,
    Description as DescriptionIcon,
    Sync as SyncIcon,
    Error as ErrorIcon,
    Person as PersonIcon,
    History as HistoryIcon
} from "@mui/icons-material";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { IconButton, TextField } from '@mui/material';
import { BillingStatusSelect } from "./BillingStatusSelect";

const billingStatusIcons = {
    "Submitted - Pending": <HourglassEmptyIcon fontSize="small" style={{ marginRight: '8px', color: 'gray' }} />,
    "Write-Off": <DeleteIcon fontSize="small" style={{ marginRight: '8px', color: 'gray' }} />,
    "Void": <BlockIcon fontSize="small" style={{ marginRight: '8px', color: 'gray' }} />,
    "Denied": <CancelIcon fontSize="small" style={{ marginRight: '8px', color: 'gray' }} />,
    "Adjusted": <RedoIcon fontSize="small" style={{ marginRight: '8px', color: 'gray' }} />,
    "Partial payment": <AttachMoneyIcon fontSize="small" style={{ marginRight: '8px', color: 'gray' }} />,
    "Paid in full": <CheckCircleIcon fontSize="small" style={{ marginRight: '8px', color: 'green' }} />,
    "Rejected": <CancelIcon fontSize="small" style={{ marginRight: '8px', color: 'gray' }} />,
    "Un-Billed": <DescriptionIcon fontSize="small" style={{ marginRight: '8px', color: 'gray' }} />,
    "Re-Submitted": <SyncIcon fontSize="small" style={{ marginRight: '8px', color: 'gray' }} />,
    "Error": <ErrorIcon fontSize="small" style={{ marginRight: '8px', color: 'red' }} />,
};

// Custom Step Icon Component
const CustomStepIcon = (props) => {
    const { active, icon } = props;

    return (
        <Box
            sx={{
                width: 40,
                height: 40,
                backgroundColor: active ? "#90caf9" : "transparent", // Blue background only for active step
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: active ? "none" : "1px solid #e0e0e0", // Optional border for inactive icons
            }}
        >
            {React.cloneElement(icon, {
                fontSize: "small",
                style: { color: active ? "#ffffff" : "gray" }, // White icon for active step, gray for others
            })}
        </Box>
    );
};

export const ServiceStatusHistoryStepper = ({ isHistoryDialogOpen, handleCloseHistoryDialog, isLoading, error, billingHistory, service, refreshBillingHistory, }) => {
    const [activeStep, setActiveStep] = useState(0);
    const [editMode, setEditMode] = useState(null); // Track the index of the step being edited
    const [editedNotes, setEditedNotes] = useState({});

    useEffect(() => {
        if (billingHistory && billingHistory.length > 0) {

            console.log("service", service);
            // Find the index of the step with the greatest ID
            setActiveStep(billingHistory.length - 1);
        }
    }, [billingHistory]);

    const handleEditClick = (index) => {
        setEditMode(index);
        setEditedNotes((prev) => ({
            ...prev,
            [index]: billingHistory[index].note || '',
        }));
    };

    const handleSaveClick = (index) => {
        // Save logic (e.g., API call) can be added here
        console.log(`Saving note for step ${index}:`, editedNotes[index]);
        setEditMode(null); // Exit edit mode
    };

    const handleNoteChange = (index, value) => {
        setEditedNotes((prev) => ({
            ...prev,
            [index]: value,
        }));
    };

    return (
        <Dialog
            id="billing-history-dialog"
            open={isHistoryDialogOpen}
            onClose={handleCloseHistoryDialog}
            aria-labelledby="billing-history-dialog-title"
            maxWidth="sm"
            fullWidth
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: '4px',
                    marginTop: '4px',
                }}
            >
                <DialogTitle id="billing-history-dialog-title">
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <HistoryIcon fontSize="medium" style={{ marginRight: '4px' }} />
                        <Typography variant="h6">{service.name} - Billing History</Typography>
                    </Box>
                </DialogTitle>
                <Box sx={{ display: 'flex', gap: 1, marginTop: '4px' }}>
                    <BillingStatusSelect name={service.billingStatus} id={service.id} onStatusChangeSuccess={refreshBillingHistory} />
                </Box>
            </Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: '22px' }}></Box>
            <DialogContent>
                {isLoading ? (
                    <CircularProgress />
                ) : error ? (
                    <Typography color="error">Error loading billing history</Typography>
                ) : billingHistory && billingHistory.length > 0 ? (
                    <Stepper activeStep={activeStep} orientation="vertical" sx={{ padding: 2 }}>
                        {billingHistory.map((history, index) => (
                            <Step key={index} expanded>
                                <StepLabel
                                    StepIconComponent={(stepProps) => (
                                        <CustomStepIcon {...stepProps} icon={billingStatusIcons[history.status] || <DescriptionIcon />} />
                                    )}
                                >
                                    {history.status} - {moment(history.statusDate).format('MM/DD/YYYY h:mm A')}
                                </StepLabel>
                                <StepContent>
                                    <Box sx={{ marginBottom: 2 }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                            <PersonIcon fontSize="small" sx={{ mr: 1 }} />
                                            <Typography variant="body2">{history.createdBy}</Typography>
                                        </Box>
                                        <Typography variant="body2">
                                            <strong>Action:</strong> {history.billingHistoryAction}
                                        </Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                            {editMode === index ? (
                                                <>
                                                    <TextField
                                                        value={editedNotes[index] || ''}
                                                        onChange={(e) => handleNoteChange(index, e.target.value)}
                                                        size="small"
                                                        sx={{ flexGrow: 1, mr: 1 }}
                                                    />
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() => handleSaveClick(index)}
                                                    >
                                                        <SaveIcon />
                                                    </IconButton>
                                                </>
                                            ) : (
                                                <>
                                                    <Typography variant="body2" sx={{ flexGrow: 1 }}>
                                                        {history.note || 'N/A'}
                                                    </Typography>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => handleEditClick(index)}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </>
                                            )}
                                        </Box>
                                    </Box>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                ) : (
                    <Typography>No billing history available</Typography>
                )}
            </DialogContent>
            <DialogActions sx={{ backgroundColor: '#edebe9' }}>
                <Button onClick={handleCloseHistoryDialog} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};
