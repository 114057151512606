import React, { useState } from 'react';
import { Box, IconButton, Select, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import Modal from '@mui/material/Modal';
import { useGetAssessmentFormsQuery, useGetServicesQuery } from '../../services/ClientService';
import { ManagePsychosocialAssessment } from './ManagePsychosocialAssessments';

const ServiceMultiSelectCell = ({ params, services, onUpdateServices }) => {
  // Extract the current linked service IDs
  const selectedServiceIds = Array.isArray(params.row.linkedServiceId)
    ? params.row.linkedServiceId.map((service) => service.id)
    : [];

  const handleChange = async (event) => {
    const newSelectedIds = event.target.value;

    // Call the update function with the new list of service IDs
    await onUpdateServices(params.row.id, newSelectedIds);

  };

  return (
    <Select
      multiple
      value={selectedServiceIds}
      onChange={handleChange}
      fullWidth
      variant="standard"
      renderValue={(selected) =>
        selected
          .map((id) => services.find((service) => service.id === id)?.name || 'Unknown')
          .join(', ')
      }
    >
      {services.map((service) => (
        <MenuItem key={service.id} value={service.id}>
          <Checkbox checked={selectedServiceIds.includes(service.id)} />
          <ListItemText primary={service.name} />
        </MenuItem>
      ))}
    </Select>
  );
};

export const FormsGrid = () => {
  const { data: assessmentForms, isLoading: isLoadingForms, error: formsError } = useGetAssessmentFormsQuery();
  const { data: services, isLoading: isLoadingServices, error: servicesError } = useGetServicesQuery();
  const [selectedFormId, setSelectedFormId] = useState(null); // State to track the selected formId
  const [modalOpen, setModalOpen] = useState(false); // State to control modal visibility

  if (isLoadingForms || isLoadingServices) return <p>Loading...</p>;
  if (formsError || servicesError) return <p>Error loading data</p>;

  const handleEditClick = (formId) => {
    setSelectedFormId(formId); // Set the selected form ID
    setModalOpen(true); // Open the modal
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedFormId(null); // Reset the form ID when closing the modal
  };

  const handleUpdateServices = async (formId, selectedServiceIds) => {
    console.log(`Updating LinkedServiceId for form ID ${formId} to service IDs ${selectedServiceIds}`);
    // Add your API call or mutation logic here
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'name', headerName: 'Assessment Name', width: 300 },
    {
      field: 'linkedServiceId',
      headerName: 'Linked Services',
      width: 400,
      renderCell: (params) => (
        <ServiceMultiSelectCell
          params={params}
          services={services || []}
          onUpdateServices={handleUpdateServices}
        />
      ),
    },
    {
      field: 'edit',
      headerName: 'Edit',
      width: 100,
      renderCell: (params) => (
        <IconButton onClick={() => handleEditClick(params.row.id)}>
          <EditIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <Box
      sx={{
        width: '100%',
        //maxWidth: 'calc(100% - 60px)',
        margin: '37px 30px',
      }}
    >
      <h2>Assessment Forms</h2>
      <div style={{ height: '500px', width: '100%', margin: '37px 0px' }}>
        <DataGrid
          rows={assessmentForms || []}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          getRowId={(row) => row.id} // Explicitly map the unique `id` field
          sx={{
            backgroundColor: 'white',
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: 'white',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
            },
          }}
        />
      </div>

      {/* Modal to display the ManagePsychosocialAssessment component */}
      <Modal open={modalOpen} onClose={handleModalClose} sx={{ overflow: 'auto' }}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
            maxHeight: '80%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          {selectedFormId && (
            <ManagePsychosocialAssessment propFormId={selectedFormId} />
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default FormsGrid;
