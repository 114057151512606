import { useState, useEffect } from "react";
import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';
import { Box, Button, ButtonGroup, Badge } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';
import { DateRangePickerDay as MuiDateRangePickerDay } from '@mui/x-date-pickers-pro/DateRangePickerDay';
import { Height, SignalCellularNull } from '@mui/icons-material';
import {
  useGetEventsMutation,
} from "../../services/ClientService";


// Styled DateRangeCalendar with custom background and border
const StyledDateRangeCalendar = styled(DateRangeCalendar)({
  '.MuiTypography-root': {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif !important',
    fontSize: '0.9rem !important', // Increase font size
    fontWeight: '500 !important', // Set font weight
    lineHeight: '1.6 !important', // Adjust line height for better spacing
  },
  '.MuiDateRangeCalendar-monthContainer': {
    maxHeight: '300px',
  }
});

const SmallButton = styled(Button)(({ theme }) => ({
  fontSize: '0.7rem', // Smaller font size
  padding: '4px 8px', // Smaller padding for a more compact look
}));

export const ServiceCalendarPicker = ({ onClose, open, type, clientId, onChange }) => {
  const [selectedDateRange, setSelectedDateRange] = useState([null, null]);
  const [badgeDates, setBadgeDates] = useState([]);
  const [getEvents] = useGetEventsMutation();
  
  useEffect(() => {
    const [startDate, endDate] = selectedDateRange;

    if (startDate && endDate) {
      const fetchEvents = async () => {
        try {
          const response = await getEvents({
            clientId,
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD'),
          }).unwrap();
          console.log("events", response);
          // Convert events into a list of badge dates
          const eventDates = response.map((event) => event.date); // Assuming API returns { date: 'YYYY-MM-DD' }
          setBadgeDates(eventDates);
        } catch (error) {
          console.error('Error fetching events:', error);
          setBadgeDates([]);
        }
      };

      fetchEvents();
    }
  }, [selectedDateRange, clientId, getEvents]);

  // Helper functions to calculate date ranges
  const calculateDateRange = (range) => {
    const today = dayjs();
    switch (range) {
      case 'today':
        return [today, today];
      case 'yesterday':
        return [today.subtract(1, 'day'), today.subtract(1, 'day')];
      case 'tomorrow':
        return [today.add(1, 'day'), today.add(1, 'day')];
      case 'thisWeek':
        return [today.startOf('week'), today.endOf('week')];
      case 'lastWeek':
        return [
          today.subtract(1, 'week').startOf('week'),
          today.subtract(1, 'week').endOf('week')
        ];
      case 'nextWeek':
        return [
          today.add(1, 'week').startOf('week'),
          today.add(1, 'week').endOf('week')
        ];
      case 'thisMonth':
        return [today.startOf('month'), today.endOf('month')];
      case 'lastMonth':
        return [
          today.subtract(1, 'month').startOf('month'),
          today.subtract(1, 'month').endOf('month')
        ];
      case 'nextMonth':
        return [
          today.add(1, 'month').startOf('month'),
          today.add(1, 'month').endOf('month')
        ];
      default:
        return [null, null];
    }
  };

  const DateRangePickerDay = styled((props) => {
    const {
      day,
      selected,
      isHighlighting,
      isStartOfHighlighting,
      isEndOfHighlighting,
      badgeDates,
      outsideCurrentMonth,
      ...other
    } = props;
  
    // Check if the current day has a badge
    const isBadgeDay = badgeDates?.some((badgeDate) =>
      dayjs(badgeDate).isSame(day, "day")
    );
  
    return (
      <Badge
        overlap="circular"
        variant="dot"
        color="primary"
        invisible={!isBadgeDay}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MuiDateRangePickerDay
          {...other}
          day={day}
          outsideCurrentMonth={outsideCurrentMonth}
          sx={{
            borderRadius: 0,
            ...(isStartOfHighlighting && {
              borderTopLeftRadius: '50%',
              borderBottomLeftRadius: '50%',
            }),
            ...(isEndOfHighlighting && {
              borderTopRightRadius: '50%',
              borderBottomRightRadius: '50%',
            }),
            ...(isHighlighting && {
              backgroundColor: '#faf9f8',
              color: 'white',
              '&:hover': {
                backgroundColor: '#faf9f8',
              },
            }),
          }}
        />
      </Badge>
    );
  })(({ theme }) => ({
    '& .MuiBadge-dot': {
      height: 8,
      minWidth: 8,
      borderRadius: '50%',
    },
  }));
  

  // Handlers for date range changes
  const handleDateRangeChange = (newRange) => {
    const calculatedRange = calculateDateRange(newRange);
    setSelectedDateRange(calculatedRange);

    // Trigger the onChange callback if provided
    if (onChange) {
      onChange(calculatedRange);
    }
  };

  const handleCalendarChange = (newRange) => {
    setSelectedDateRange(newRange);

    // Trigger the onChange callback if provided
    if (onChange) {
      onChange(newRange);
    }
  };

return (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'white',
        border: '1px solid #ccc',
        borderRadius: '8px',
        padding: 2,
        width: 'fit-content',
        height: '400px', // Fixed height
        overflow: 'hidden', // Prevent overflow causing scrollbars
        marginTop: '8px',
      }}
    >
      {/* Calendar on the left side */}
      <StyledDateRangeCalendar
        calendars={1}
        value={selectedDateRange}
        onChange={handleCalendarChange}
        showDaysOutsideCurrentMonth
        disableHighlightToday
        slots={{
          day: (props) => <DateRangePickerDay {...props} badgeDates={badgeDates} />,
        }}
      />

      {/* Button groups stacked vertically and centered on the right side */}
      <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: 2 }}>
        {/* Day Buttons */}
        <ButtonGroup size="small" orientation="vertical" sx={{ marginBottom: 2 }}>
          <SmallButton onClick={() => handleDateRangeChange('yesterday')}>Yesterday</SmallButton>
          <SmallButton onClick={() => handleDateRangeChange('today')}>Today</SmallButton>
          <SmallButton onClick={() => handleDateRangeChange('tomorrow')}>Tomorrow</SmallButton>
        </ButtonGroup>

        {/* Week Buttons */}
        <ButtonGroup size="small" orientation="vertical" sx={{ marginBottom: 2 }}>
          <SmallButton onClick={() => handleDateRangeChange('lastWeek')}>Last Week</SmallButton>
          <SmallButton onClick={() => handleDateRangeChange('thisWeek')}>This Week</SmallButton>
          <SmallButton onClick={() => handleDateRangeChange('nextWeek')}>Next Week</SmallButton>
        </ButtonGroup>

        {/* Month Buttons */}
        <ButtonGroup size="small" orientation="vertical">
          <SmallButton onClick={() => handleDateRangeChange('lastMonth')}>Last Month</SmallButton>
          <SmallButton onClick={() => handleDateRangeChange('thisMonth')}>This Month</SmallButton>
          <SmallButton onClick={() => handleDateRangeChange('nextMonth')}>Next Month</SmallButton>
        </ButtonGroup>
      </Box>
    </Box>
  </LocalizationProvider>
);
};
