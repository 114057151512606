import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  status: 0,
  counselor: 0,
  housing: 0,
  levelofcare: 0,
  billingstatus: null,
  servicestatus: null,
  service: null,
  authstatus: null,
  client: null,
  serviceowner: null,
  authorizationstatus: null,
}

const filterSlice = createSlice({
    name: "filterSlice",
    initialState,
    reducers: {
      setStatus: (state, action) => {state.status = action.payload},
      setCounselor: (state, action) => {state.counselor = action.payload},
      setHousing: (state, action) => {state.housing = action.payload},
      setLevelofCare: (state, action) => {state.levelofcare = action.payload},
      setBillingStatus: (state, action) => {state.billingstatus = action.payload},
      setServiceStatus: (state, action) => {state.servicestatus = action.payload},
      setService: (state, action) => {state.service = action.payload},
      setAuthStatus: (state, action) => {state.authstatus = action.payload},
      setAuthorizationStatus: (state, action) => {state.authorizationstatus = action.payload},
      setClient: (state, action) => {
        // Check if action.payload is null; if so, set state.client to null
        if (action.payload) {
          console.log("Full action.payload:", action.payload); // Log the full payload
          console.log("action.payload.id:", action.payload.id); // Log the specific id
          state.client = action.payload
        } else {
          state.client = null;
        }
      },
      setServiceOwner: (state, action) => {state.serviceowner = action.payload},
    }
  });
  
  
  export const { setStatus, setCounselor, setHousing, setLevelofCare, setBillingStatus, setService, setAuthStatus, setClient, setAuthorizationStatus, setServiceStatus, setServiceOwner } =  filterSlice.actions;
  export default filterSlice.reducer